import React from "react";
import { Line, Bar, Pie } from "react-chartjs-2";

import {
  chartExample1,
  chartExample4,
  chartExample9,
  chartExample10,
  chartExample11,
  chartExample12,
} from "variables/charts.js";

export default function PackageCharts(props) {
  let { headers, countsValues } = props

  return (
    <>
      <Bar
        data={{
          labels: headers,
          datasets: [{
            borderColor: "#e91e63",
            fill: true,
            backgroundColor: "#e91e63",
            hoverBorderColor: "#e91e63",
            barPercentage: 0.2,
            data: countsValues[0]
          }]
        }}
        width={1500}
        height={300}
        options={chartExample10.options}
      />
    </>
  );
}
