require('dotenv').config()
import React, { useState, useEffect } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
// import ContentCopy from "@material-ui/icons/ContentCopy";
import Store from "@material-ui/icons/Store";
// import InfoOutline from "@material-ui/icons/InfoOutline";
import Warning from "@material-ui/icons/Warning";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Refresh from "@material-ui/icons/Refresh";
import Edit from "@material-ui/icons/Edit";
import Place from "@material-ui/icons/Place";
import ArtTrack from "@material-ui/icons/ArtTrack";
import Language from "@material-ui/icons/Language";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Danger from "components/Typography/Danger.js";
import Success from "components/Typography/Success.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import axios from "axios";


const useStyles = makeStyles(styles);

export default function Dashboard() {
  const [status, setStatus] = useState({})

  useEffect(async () => {
    if (!window.localStorage.getItem('userAccessToken')) {
      window.location.href = `http://${window.location.host}/auth/`
    }
    await axios.get(`${process.env.REACT_APP_API}/data/api`, { timeout: 3000 }).then((result) => setStatus(prev => {
      return {
        ...prev,
        api: result.data.api
      }
    }))
    await axios.post(`${process.env.REACT_APP_API}/data/slave`, '', {
      headers: {
        authorization: window.localStorage.getItem('userAccessToken')
      }
    }).then(result => {
      setStatus((prev) => {
        return {
          ...prev,
          slave: result.data.slave
        }
      })
    })
    await axios.post(`${process.env.REACT_APP_API}/data/master`, '', {
      headers: {
        authorization: window.localStorage.getItem('userAccessToken')
      }
    }, { timeout: 3000 }).then(result => {
      setStatus((prev) => {
        return {
          ...prev,
          master: result.data.master
        }
      })
    })
    await axios.get(`${process.env.REACT_APP_API}/data/ping`, '', { timeout: 3000 }).then(result => {
      setStatus((prev) => {
        return {
          ...prev,
          mvhub: result.data.mvhub,
          api_mvhub: result.data.api_mvhub
        }
      })
    })
    await axios.post(process.env.REACT_APP_GB_API, {
      referenceNo: "694437278001"
    }, {
      headers: {
        'Authorization': process.env.REACT_APP_GB_API_KEY,
        'Content-Type': 'application/json',
      }
    }).then(result => {
      if (result) {
        setStatus((prev) => {
          return {
            ...prev,
            gb: 'online'
          }
        })
      }
    })
    await axios.post(process.env.REACT_APP_GLOBAL_API, {
      referenceNo: "694437278001"
    }, {
      headers: {
        'Authorization': process.env.REACT_APP_GLOBAL_API_KEY,
        'Content-Type': 'application/json',
      }
    }).then(result => {
      if (result) {
        setStatus((prev) => {
          return {
            ...prev,
            global: 'online'
          }
        })
      }
    })
  }, [])
  const classes = useStyles();
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={6} md={6} lg={4}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <h3>Report-API</h3>
              </CardIcon>
              <p className={classes.cardCategory}>status</p>
              <h3 className={classes.cardTitle}>
                {status.api === 'online' &&
                  <>
                    <Success>
                      <CheckCircleIcon />
                    </Success>
                    ONLINE
                  </>
                }
                {!status.api &&
                  <>
                    <Danger>
                      <Warning />
                    </Danger>
                    OFFLINE
                  </>
                }
              </h3>
            </CardHeader>
            <CardFooter>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={4}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <h3>DB mvhub slave</h3>
              </CardIcon>
              <p className={classes.cardCategory}>status</p>
              <h3 className={classes.cardTitle}>
                {status.slave === 'online' &&
                  <>
                    <Success>
                      <CheckCircleIcon />
                    </Success>
                    ONLINE
                  </>
                }
                {!status.slave &&
                  <>
                    <Danger>
                      <Warning />
                    </Danger>
                    OFFLINE
                  </>
                }
              </h3>
            </CardHeader>
            <CardFooter >
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={4}>
          <Card>
            <CardHeader color="rose" stats icon>
              <CardIcon color="rose">
                <h3>DB mvhub master</h3>
              </CardIcon>
              <p className={classes.cardCategory}>status</p>
              <h3 className={classes.cardTitle}>
                {status.master === 'online' &&
                  <>
                    <Success>
                      <CheckCircleIcon />
                    </Success>
                    ONLINE
                  </>
                }
                {!status.master &&
                  <>
                    <Danger>
                      <Warning />
                    </Danger>
                    OFFLINE
                  </>
                }
              </h3>
            </CardHeader>
            <CardFooter >
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={6}>
          <Card>
            <CardHeader color="danger" stats icon>
              <CardIcon color="danger">
                <h3>Web-MVhub</h3>
              </CardIcon>
              <p className={classes.cardCategory}>status</p>
              <h3 className={classes.cardTitle}>
                {status.mvhub === 'online' &&
                  <>
                    <Success>
                      <CheckCircleIcon />
                    </Success>
                    ONLINE
                  </>
                }
                {!status.mvhub &&
                  <>
                    <Danger>
                      <Warning />
                    </Danger>
                    OFFLINE
                  </>
                }
              </h3>
            </CardHeader>
            <CardFooter>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={6}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <h3>API-MVhub</h3>
              </CardIcon>
              <p className={classes.cardCategory}>status</p>
              <h3 className={classes.cardTitle}>
                {status.api_mvhub === 'online' &&
                  <>
                    <Success>
                      <CheckCircleIcon />
                    </Success>
                    ONLINE
                  </>
                }
                {!status.api_mvhub &&
                  <>
                    <Danger>
                      <Warning />
                    </Danger>
                    OFFLINE
                  </>
                }
              </h3>
            </CardHeader>
            <CardFooter >
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={6}>
          <Card>
            <CardHeader color="primary" stats icon>
              <CardIcon color="primary">
                <h3>gbprimepay</h3>
              </CardIcon>
              <p className={classes.cardCategory}>status</p>
              <h3 className={classes.cardTitle}>
                {status.gb === 'online' &&
                  <>
                    <Success>
                      <CheckCircleIcon />
                    </Success>
                    ONLINE
                  </>
                }
                {!status.gb &&
                  <>
                    <Danger>
                      <Warning />
                    </Danger>
                    OFFLINE
                  </>
                }
              </h3>
            </CardHeader>
            <CardFooter>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={6}>
          <Card>
            <CardHeader color="rose" stats icon>
              <CardIcon color="rose">
                <h3>globalprimepay</h3>
              </CardIcon>
              <p className={classes.cardCategory}>status</p>
              <h3 className={classes.cardTitle}>
                {status.global === 'online' &&
                  <>
                    <Success>
                      <CheckCircleIcon />
                    </Success>
                    ONLINE
                  </>
                }
                {!status.global &&
                  <>
                    <Danger>
                      <Warning />
                    </Danger>
                    OFFLINE
                  </>
                }
              </h3>
            </CardHeader>
            <CardFooter>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
      </GridContainer>
    </div>
  );
}