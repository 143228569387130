import React, { useState, useEffect, useRef } from "react";
import { Route, Switch } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";

// @material-ui/icons
import CircularProgress from '@material-ui/core/CircularProgress';
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
// core components
import Accordion from "components/Accordion/Accordion.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import axios from "axios";
import ExtendedTablesPartnerForm from "views/Tables/ExtendedTablesPartnerForm";
import '../../assets/css/style.css'
import InputForm from "../Forms/InputForm";
import DateForm from "../Forms/DateForm";
import NA_UserDetail from "../NotAdminForms/NA_UserDetail";

const useStyles = makeStyles(styles);

export default function NA_PartnerSearchUserForm() {
  const [input, setInput] = useState({ datecategory: "user_partner.create_at", serial: '', user_id: '', username: '', status: '', expire_pack: '', user_id_old: ''})
  const [datas, setDatas] = useState({})
  let defaultRef = useRef(true)
  const [simpleSelect, setSimpleSelect] = useState();
  const [loadingStatus, setLoadingStatus] = useState(false)
  const [status, setStatus] = useState(false)
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState()
  const classes = useStyles();

  function inputChange(event) {
    const { name, value } = event.target
    setInput((prev) => {
      return {
        ...prev,
        [name]: value
      }
    })
  }
  function dateChange(_d, name) {
    setInput((prev) => {
      return {
        ...prev,
        [name]: _d
      }
    })
  }

  const handleSimple = (event) => {
    setSimpleSelect(event.target.value);
    const { name, value } = event.target
    setInput((prev) => {
      return {
        ...prev,
        [name]: value
      }
    })
  };
  //Search+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  function clickSubmit(e) {
    e.preventDefault();
    defaultRef.current = false
    setLoadingStatus(true)
    axios.post(`${process.env.REACT_APP_API}/formpartner/searchuser?page=1/`, input, {
      headers: {
        authorization: window.localStorage.getItem('userAccessToken')
      }
    }, {
      timeout: 9999999
    }).then(result => {
      setTotalPages(result.data.totalPages)
      setDatas(result.data.result)
      setPage(1)
    })
      .finally(() => {
        setLoadingStatus(false)
        setStatus(true)
      })
  }
  //Search---------------------------------------------------------------
  //Pages++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  function clickPage(page) {
    if (defaultRef.current != true) {
      axios.post(`${process.env.REACT_APP_API}/formpartner/searchuser?page=${page}/`, input, {
        headers: {
          authorization: window.localStorage.getItem('userAccessToken')
        }
      }, {
        timeout: 9999999
      }).then(result => {
        setTotalPages(result.data.totalPages)
        setDatas(result.data.result)
        setPage(page)
      })
        .finally(() => {
          setStatus(true)
        })
    } else {
      axios.post(`${process.env.REACT_APP_API}/formpartner/searchuser?page=${page}/`, {
        datecategory: "user_partner.create_at", serial: '', user_id: '', username: '', status: '', expire_pack: '', user_id_old: '',
        startdate: new Date().setDate(new Date().getDate() - 1), enddate: new Date().setDate(new Date().getDate())
      }, {
        headers: {
          authorization: window.localStorage.getItem('userAccessToken')
        }
      }, {
        timeout: 9999999
      }).then(result => {
        setTotalPages(result.data.totalPages)
        setDatas(result.data.result)
        setPage(page)
      })
        .finally(() => {
          setStatus(true)
        })
    }
  }
  //Pages-----------------------------------------------------------------
  useEffect(() => {
    if (!window.localStorage.getItem('userAccessToken')) {
      window.location.href = `http://${window.location.host}/auth/`
    }
    axios.post(`${process.env.REACT_APP_API}/formpartner/searchuser?page=1/`,
      {
        datecategory: "user_partner.create_at", serial: '', user_id: '', username: '', status: '', expire_pack: '', user_id_old: '',
        startdate: new Date().setDate(new Date().getDate() - 1), enddate: new Date().setDate(new Date().getDate())
      },
      {
        headers: {
          authorization: window.localStorage.getItem('userAccessToken')
        }
      }, {
      timeout: 99999
    }).then(result => {
      setTotalPages(result.data.totalPages)
      setDatas(result.data.result)
      setPage(1)
      console.log("xxxxxxxxxxxxxxxxxxxxxxxx")
      console.log(result.data.result)
    })
      .finally(() => {
        setLoadingStatus(false)
        setStatus(true)
      })
  }, [])
  return (
    <Switch>
      <Route path='/admin/searchuser/detail/:id' component={NA_UserDetail} exact />
      <Route path='/admin/searchuser/' exact>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardBody>
                <form onSubmit={clickSubmit}>
                  <InputForm
                    inputChange={inputChange}
                    input={input}
                  />
                  <Accordion
                    collapses={[
                      {
                        title: "Search from register day (ค้นหาจากวันที่)",
                        content: (
                          <DateForm
                            dateChange={dateChange}
                            inputChange={inputChange}
                            input={input.datecategory}
                            handleSimple={handleSimple}
                          />
                        ),
                      },
                    ]}
                  />
                  <Button type='submit' color="info" block>Submit</Button>
                </form>
              </CardBody>
            </Card>
          </GridItem>
          {
            loadingStatus == true && status == false &&
            <CircularProgress color="secondary" className='loading' />
          }
          <GridItem xs={12} sm={12} md={12}>
            {status == true &&
              <ExtendedTablesPartnerForm
                loadingStatus={loadingStatus}
                headers={['serial','user_id', 'username', 'status', 'expire_pack', 'user_id_old']}
                datatable={datas}
                page={page}
                totalPages={totalPages}
                clickPage={clickPage}
              />
            }
          </GridItem>
        </GridContainer >
      </Route>
    </Switch>
  );
}
