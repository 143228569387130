import React, { useEffect, useState } from "react";
// react component plugin for creating a beautiful datetime dropdown picker
import Datetime from "react-datetime";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";

// @material-ui/icons
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import CircularProgress from '@material-ui/core/CircularProgress';

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import axios from "axios";
import NoWatchTable from "views/Tables/NoWatchTable";

const useStyles = makeStyles(styles);

export default function NoWatch() {
  const [input, setInput] = useState({})
  const [datas, setDatas] = useState({})
  const [loadingStatus, setLoadingStatus] = useState(false)
  const [status, setStatus] = useState(false)
  const [next, setNext] = useState([])
  const [previous, setPrevious] = useState([])

  function inputChange(_d, name) {
    console.log(_d)
    setInput((prev) => {
      return {
        ...prev,
        [name]: _d
      }
    })
  }
  function submit() {
    if (Object.keys(input).length = 2) {
      setLoadingStatus(true)
      axios.post(`${process.env.REACT_APP_API}/vod/nowatch?page=1`, input, {
        headers: {
          authorization: window.localStorage.getItem('userAccessToken')
        }
      }).then(result => {
        setDatas(result.data.result)
        result.data.next ? setNext(['primary', result.data.next.page]) : setNext('')
        result.data.previous ? setPrevious(['primary', result.data.previous.page]) : setPrevious('')
      }).finally(() => {
        setLoadingStatus(false)
        setStatus(true)
      })
    }
  }
  function clickPage(e, page) {
    if (Object.keys(input).length = 2) {
      setLoadingStatus(true)
      axios.post(`${process.env.REACT_APP_API}/vod/nowatch?page=${page}`, input, {
        headers: {
          authorization: window.localStorage.getItem('userAccessToken')
        }
      }).then(result => {
        setDatas(result.data.result)
        result.data.next ? setNext(['primary', result.data.next.page]) : setNext('')
        result.data.previous ? setPrevious(['primary', result.data.previous.page]) : setPrevious('')
      }).finally(() => {
        setLoadingStatus(false)
        setStatus(true)
      })
    }
  }

  useEffect(() => {
    if (!window.localStorage.getItem('userAccessToken')) {
      window.location.href = `http://${window.location.host}/auth`
    }
    submit()
  }, [])
  const classes = useStyles();
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="success" icon>
              <CardIcon color="success">
                <LibraryBooks />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>จำนวนการรับชม ตั้งแต่</h4>
            </CardHeader>
            <CardBody>
              <FormControl fullWidth>
                <Datetime
                  timeFormat={false}
                  inputProps={{ placeholder: "ตั้งแต่" }}
                  name='startdate'
                  onChange={(event) => inputChange(event._d, 'startdate')}
                />
              </FormControl>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="success" icon>
              <CardIcon color="success">
                <LibraryBooks />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>จำนวนการรับชม จนถึง</h4>
            </CardHeader>
            <CardBody>
              <FormControl fullWidth>
                <Datetime
                  timeFormat={false}
                  inputProps={{ placeholder: "จนถึง" }}
                  onChange={(event) => inputChange(event._d, 'enddate')}
                />
              </FormControl>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <Button color="success" className={classes.marginRight} block onClick={submit}>
        Search
      </Button>
      {loadingStatus == true && status == false &&
        <CircularProgress color="secondary" className='loading' />
      }
      <GridItem xs={12} sm={12} md={12}>
        {status == true &&
          <NoWatchTable
            loadingStatus={loadingStatus}
            headers={['ID', 'Globalname', 'Image']}
            datatable={datas}
            next={next}
            previous={previous}
            clickPage={clickPage}
          />
        }
      </GridItem>
    </div>
  );
}
