import Dashboard from "views/Dashboard/Dashboard.js";

// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";
import Image from "@material-ui/icons/Image";
import EqualizerIcon from '@material-ui/icons/Equalizer';
import Timeline from "@material-ui/icons/Timeline";
import LogoutPage from "views/Pages/LogoutPage";
import SearchUserForm from "views/Forms/SearchUserForm";
import HistoryPaymentForm from "views/Forms/HistoryPaymentForm";
import Watch from "views/VOD/Watch";
import NoWatch from "views/VOD/NoWatch";
import Hash from "views/VOD/Hash";
import Package from "views/Pages/Package";
import ChartsPackage from "views/Charts/ChartsPackage";
import ChartsUsers from "views/Charts/ChartsUsers";
import ChartsVod from "views/Charts/ChartsVod";
import PersonIcon from "@material-ui/icons/Person";
import NA_Package from "views/NotAdminPages/NA_Package";
import NA_SearchUserForm from "views/NotAdminForms/NA_SearchUserForm";
import NA_PartnerSearchUserForm from "views/NotAdminForms/NA_PartnerSearchUserForm";
import ChartsLinePackage from "views/Charts/ChartsLinePackage";
import ChartsLineSale from "views/Charts/ChartsLineSale";

if (!window.localStorage.getItem('role')) {
  var dashRoutes = [
    {
      path: "/service",
      name: "Service",
      icon: EqualizerIcon,
      component: Dashboard,
      layout: "/admin",
    },
    {
      path: "/logout",
      name: "Logout",
      icon: DashboardIcon,
      component: LogoutPage,
      layout: "/admin",
    },
  ];
} else if (window.localStorage.getItem('role') == 1) {
  var dashRoutes = [
    {
      path: "/searchuser",
      name: "Search User(ค้นหา)",
      icon: PersonIcon,
      component: SearchUserForm,
      layout: "/admin",
    },
    {
      path: "/package",
      name: "Package",
      icon: DashboardIcon,
      component: Package,
      layout: "/admin",
    },
    {
      path: "/historypayment",
      name: "Payment",
      icon: Timeline,
      component: HistoryPaymentForm,
      layout: "/admin",
    },
    {
      collapse: true,
      name: "Summary",
      icon: EqualizerIcon,
      state: "summaryCollapse",
      views: [
        {
          path: "/summary/package",
          name: "Package",
          icon: EqualizerIcon,
          component: ChartsPackage,
          layout: "/admin",
        },
        {
          path: "/summary/users",
          name: "Users",
          icon: EqualizerIcon,
          component: ChartsUsers,
          layout: "/admin",
        },
        {
          path: "/summary/vod",
          name: "Vod",
          icon: EqualizerIcon,
          component: ChartsVod,
          layout: "/admin",
        },
      ],
    },
    {
      collapse: true,
      name: "VOD Report",
      icon: Image,
      state: "componentsCollapse",
      views: [
        {
          path: "/watch",
          name: "VOD Watch",
          icon: Timeline,
          component: Watch,
          layout: "/admin",
        },
        {
          path: "/nowatch",
          name: "VOD No Watch",
          icon: Timeline,
          component: NoWatch,
          layout: "/admin",
        },
        {
          path: "/hash",
          name: "VOD Hash",
          icon: Timeline,
          component: Hash,
          layout: "/admin",
        }
      ],
    },
    {
      path: "/service",
      name: "Service",
      icon: EqualizerIcon,
      component: Dashboard,
      layout: "/admin",
    },
    {
      collapse: true,
      name: "CS Report",
      icon: PersonIcon,
      views: [
        {
          path: "/cspackage",
          name: "Summary Report",
          icon: PersonIcon,
          component: ChartsLinePackage,
          layout: "/admin",
        },
        {
          path: "/cssale",
          name: "Sale Report",
          icon: PersonIcon,
          component: ChartsLineSale,
          layout: "/admin",
        }
      ],
    },
    {
      path: "/logout",
      name: "Logout",
      icon: DashboardIcon,
      component: LogoutPage,
      layout: "/admin",
    },
  ];
} else if (window.localStorage.getItem('role') == 2) {
  var dashRoutes = [
    {
      path: "/searchuser",
      name: "Search User(ค้นหา)",
      icon: PersonIcon,
      component: NA_SearchUserForm,
      layout: "/admin",
    },
    {
      path: "/partnersearchuser",
      name: "Search User(Partner)",
      icon: PersonIcon,
      component: NA_PartnerSearchUserForm,
      layout: "/admin",
    },
    {
      path: "/historypayment",
      name: "Payment",
      icon: Timeline,
      component: HistoryPaymentForm,
      layout: "/admin",
    },  
    {
      path: "/logout",
      name: "Logout",
      icon: DashboardIcon,
      component: LogoutPage,
      layout: "/admin",
    },
  ];
} else if (window.localStorage.getItem('role') == 3) {
  var dashRoutes = [
    {
      path: "/service",
      name: "Package",
      icon: EqualizerIcon,
      component: ChartsPackage,
      layout: "/admin",
    },
    {
      path: "/searchuser",
      name: "Users",
      icon: EqualizerIcon,
      component: ChartsUsers,
      layout: "/admin",
    },
    {
      path: "/vod",
      name: "Vod",
      icon: EqualizerIcon,
      component: ChartsVod,
      layout: "/admin",
    },
    {
      path: "/logout",
      name: "Logout",
      icon: DashboardIcon,
      component: LogoutPage,
      layout: "/admin",
    },
  ];
}

export default dashRoutes;