import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";

// material-ui icons
import Assignment from "@material-ui/icons/Assignment";
import CircularProgress from '@material-ui/core/CircularProgress';
// core components
import Badge from "components/Badge/Badge.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import GridContainer from "components/Grid/GridContainer";
import Pagination from "components/Pagination/Pagination";
import { Link } from "react-router-dom";
import axios from "axios";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

const useStyles = makeStyles(styles);

export default function OrdersTable(props) {
  const { id } = props
  const [loadingStatus, setLoadingStatus] = useState(true)
  const [datas, setDatas] = useState({});
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState()
  const classes = useStyles();
  let data = []
  if (datas.length > 0) {
    data = datas.map(result => {
      return (
        [
          <p>{result.referenceNo}</p>,
          <Link to={`/admin/package/detail/${result.package_id}`}><p>{result.package_name}</p></Link>,
          result.res_cd == '00' ? <Badge color='success'>success</Badge> : <Badge color='danger'>({result.res_cd}) {result.res_msg}</Badge>,
          //result.res_cd == '00' ? <Badge color='success'>{result.res_msg}</Badge> : <Badge color='warning'>{result.res_msg}</Badge>,
          <p>{new Date(result.create_at).toLocaleString('th')}</p>
        ]
      )
    })
  }
  const clickPage = (page) => {
    axios.post(`${process.env.REACT_APP_API}/form/orders?id=${id}&page=${page}`, { username: id }, {
      headers: {
        authorization: window.localStorage.getItem('userAccessToken')
      }
    }).then(result => {
      setDatas(result.data.result)
      setTotalPages(result.data.totalPages)
      setPage(page)
    }).finally(() => setLoadingStatus(false))
  }
  useEffect(() => {
    axios.post(`${process.env.REACT_APP_API}/form/orders?id=${id}`, { username: id }, {
      headers: {
        authorization: window.localStorage.getItem('userAccessToken')
      }
    }).then(result => {
      setDatas(result.data.result)
      setTotalPages(result.data.totalPages)
      setPage(1)
    }).finally(() => setLoadingStatus(false))
  }, [])
  return (
    <>
      <Card>
        <CardHeader color="rose" icon>
          <CardIcon color="rose">
            <Assignment />
          </CardIcon>
        </CardHeader>
        <CardBody>
          {loadingStatus == true &&
            <CircularProgress color="secondary" className='loading' />
          }
          {loadingStatus == false &&
            <>
              <GridContainer
                justify="center"
              >
                <Pagination
                  pages={[
                    { text: 'First', value: 1 },
                    page - 2 > 0 ? { text: page - 2, value: page - 2 } : { value: page },
                    page - 1 > 0 ? { text: page - 1, value: page - 1 } : { value: page },
                    { active: true, text: page, value: page },
                    page + 1 <= totalPages ? { text: page + 1, value: page + 1 } : { value: page },
                    page + 2 <= totalPages ? { text: page + 2, value: page + 2 } : { value: page },
                    { text: 'Last', value: totalPages },
                  ]}
                  color="primary"
                  clickPage={clickPage}
                />
              </GridContainer>
              <Table
                tableHead={['Ref No.', 'Package', 'Status', 'Transaction Date']}
                tableData={data}
                customCellClasses={[
                  classes.left,
                  classes.left,
                  classes.center,
                  classes.right,
                  classes.right,
                ]}
                customClassesForCells={[0, 1, 2, 3, 4]}
                customHeadCellClasses={[
                  classes.left,
                  classes.left,
                  classes.center,
                  classes.right,
                  classes.right,
                ]}
                customHeadClassesForCells={[0, 1, 2, 3, 4]}
              />
            </>
          }
        </CardBody>
      </Card>
    </>
  );
}
