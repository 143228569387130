/*eslint-disable*/
import React, { useEffect, useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from '@material-ui/core/CircularProgress';

// @material-ui/icons
import Timeline from "@material-ui/icons/Timeline";
import Datetime from "react-datetime";
// import Accordion from "components/Accordion/Accordion.js"; เก็บเอาไว้เผื่อใช้
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import ReactExport from "react-export-excel";
import Pagination from "components/Pagination/Pagination";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import stylesSelect from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import axios from "axios";
import PackageCharts from "./PackageCharts";
import Table from "components/Table/Table.js";

const useStyles = makeStyles(styles);
const useStylesSelect = makeStyles(stylesSelect);

export default function ChartsLinePackage() {
  const classes = useStyles();
  const classesSelect = useStylesSelect();
  const [input, setInput] = useState({})
  const [datas, setDatas] = useState({})
  // const [limit, setLimit] = useState(21) เก็บเอาไว้เผื่อใช้
  const [subTotal, setSubTotal] = useState([])
  const [subTotalPage, setSubTotalPage] = useState(0)
  const [total, setTotal] = useState(0)
  const [chartHeader, setChartHeader] = useState()
  const [chartData, setChartData] = useState()
  const [simpleSelect, setSimpleSelect] = useState('1');
  const [loading, setLoading] = useState()
  const [page, setPage] = useState(1)
  // let renderTableHeader เก็บเอาไว้เผื่อใช้
  let Datas = []
  let Total = 0
  let SubTotal = []

  //excel
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
  //excel

  function dateChange(_d, name) {
    setInput((prev) => {
      return {
        ...prev,
        [name]: _d
      }
    })
  }

  const groupBy = (items, key) => items.reduce(
    (result, item) => ({
      ...result,
      [item[key]]: [
        ...(result[item[key]] || []),
        item,
      ],
    }),
    {},
  );

  const handleSimple = async (event) => {
    setSimpleSelect(event.target.value);
  };

  function clickSubmit(event) {
    event.preventDefault()
    setLoading(true)
    axios.post(`${process.env.REACT_APP_API}/report/cspackage`, { input }, {
      headers: {
        authorization: window.localStorage.getItem('userAccessToken')
      }
    }).then(result => {
      setDatas(result.data.values)
      for (let i of Datas) {
        Total += i
      }
      for (let i of Object.values(groupBy(result.data.values, 'package_id'))) {
        SubTotal.push({ package_id: i[0].package_id, good_name: i[0].good_name,counts: i.length })
      }
    })
      .finally(() => {
        setChartData([Datas])
        setTotal(Total)
        setSubTotalPage(Math.ceil(SubTotal.length / 10))
        setSubTotal(SubTotal)
        setLoading(false)
      })
  }

  function getSubTotal(pageClick) {
    return (
      subTotal
        .filter((data, index) => index >= (pageClick - 1) * 10 && index < (pageClick * 10))
        .map((data) => [
          <p>{data.package_id}</p>,
          <p>{data.good_name}</p>,
          <p>{data.counts}</p>
        ])
    )
  }

  function clickPage(value) {
    setPage(value)
  }

  useEffect(() => {
    if (!window.localStorage.getItem('userAccessToken')) {
      window.location.href = `http://${window.location.host}/auth/`
    }
    setLoading(true)
    axios.post(`${process.env.REACT_APP_API}/report/cspackage`, {
      filter: simpleSelect,
      input: {
        startdate: new Date(), 
        enddate: new Date(),
      }
    }, {
      headers: {
        authorization: window.localStorage.getItem('userAccessToken')
      }
    }).then(result => {
      setDatas(result.data.values)
      for (let i of Datas) {
        Total += i
      }
      for (let i of Object.values(groupBy(result.data.values, 'package_id'))) {
        SubTotal.push({ package_id: i[0].package_id, good_name: i[0].good_name, counts: i.length })
      }
    })
      .finally(() => {
        setChartData([Datas])
        setTotal(Total)
        setSubTotalPage(Math.ceil(SubTotal.length / 10))
        setSubTotal(SubTotal)
        setLoading(false)
      })
  }, [])
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <Timeline />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                Line Package
              </h4>
            </CardHeader>
            <CardBody>
              <form onSubmit={clickSubmit}>
                <GridContainer>
                  <GridItem sm={3} md={3} lg={3} >
                    <GridContainer
                      justify="space-between"
                      alignItems="center"
                      direction="row"
                    >
                      <Datetime
                        timeFormat={false}
                        inputProps={{ placeholder: "ตั้งแต่", required: true }}
                        name='startdate'
                        onChange={(event) => dateChange(event._d, 'startdate')}
                      />
                      <Datetime
                        timeFormat={false}
                        inputProps={{ placeholder: "จนถึง", required: true }}
                        name='enddate'
                        onChange={(event) => dateChange(event._d, 'enddate')}
                      />
                      <FormControl
                        fullWidth
                        className={classesSelect.selectFormControl}
                      >
                        <Button className={classesSelect.selectFormControl}
                          color='primary'
                          type='submit'
                        >submit</Button>
                      </FormControl>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
                {loading === true &&
                  <CircularProgress color="secondary" className='loading' />
                }
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer >
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
           {/* <CardHeader>
              <ExcelFile element={<Button color='success'>Export</Button>}>
                <ExcelSheet data={subTotal} name="package-register-counts">
                  <ExcelColumn label="Package ID" value="package_id" />
                  <ExcelColumn label="Package Name" value="good_name" />
                  <ExcelColumn label="Count(s)" value="counts" />
                </ExcelSheet>
                <ExcelSheet data={datas} name="package-register-logs">
                  <ExcelColumn label="Create date" value="create_at" />
                  <ExcelColumn label="User ID" value="user_id" />
                  <ExcelColumn label="Package ID" value="package_id" />
                  <ExcelColumn label="Package name" value="pack_name" />
                </ExcelSheet>
              </ExcelFile>
            </CardHeader> */}
            <CardBody >
              {loading == false && datas.length > 0 &&
                <>
                  <GridContainer
                    justify="center"
                  >
                    <Pagination
                      pages={[
                        { text: 'First', value: 1 },
                        page - 2 > 0 ? { text: page - 2, value: page - 2 } : { value: page },
                        page - 1 > 0 ? { text: page - 1, value: page - 1 } : { value: page },
                        { active: true, text: page, value: page },
                        page + 1 <= subTotalPage ? { text: page + 1, value: page + 1 } : { value: page },
                        page + 2 <= subTotalPage ? { text: page + 2, value: page + 2 } : { value: page },
                        { text: 'Last', value: subTotalPage },
                      ]}
                      color="primary"
                      clickPage={clickPage}
                    />
                  </GridContainer>

                  <Table
                    tableHead={['Package ID','Package Name', 'Count(s)']}
                    tableData={getSubTotal(page)}
                    customCellClasses={[
                      classes.left,
                      classes.center,
                      classes.left,
                      classes.center,
                      classes.center,
                      classes.right
                    ]}
                    customClassesForCells={[0, 1, 2, 3, 4, 5]}
                    customHeadCellClasses={[
                      classes.left,
                      classes.center,
                      classes.left,
                      classes.center,
                      classes.center,
                      classes.right
                    ]}
                    customHeadClassesForCells={[0, 1, 2, 3, 4, 5]}
                  />
                  {/* <Accordion  เก็บเอาไว้เผื่อใช้
                    collapses={
                      renderTableHeader
                    }
                  /> */}
                </>
              }
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div >
  );
}
