import React, { useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// material-ui icons
import Assignment from "@material-ui/icons/Assignment";
import CircularProgress from '@material-ui/core/CircularProgress';

// core components
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Pagination from "components/Pagination/Pagination";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import { Link } from "react-router-dom";
import GridContainer from "components/Grid/GridContainer";

const useStyles = makeStyles(styles);

export default function HistoryPaymentTable(props) {
  const { headers, datatable, page, totalPages, clickPage, loadingStatus } = props
  const classes = useStyles();

  const renderDataTable = datatable.map(data => {
    return (
      [
        <p>
          {data.referenceNo}
        </p>,
        <Link to={`/admin/searchuser/detail/${data.user_id}`}>
          <span key="key">
            {data.user_id}
            <br />
          </span>
        </Link>,
        <Link to={`/admin/package/detail/${data.package_id}`}>
          <span>
            {data.good_name}
            <br />
          </span>
        </Link>,
        <p>
          {data.res_msg}
        </p>,
        <p>
          {data.trade_mony}.-
        </p>,
        <p>{new Date(data.create_at).toLocaleString('th')}</p>
      ]
    )
  })
  return (
    <Card>
      <CardHeader color="success" icon>
        <CardIcon color="success">
          <Assignment />
        </CardIcon>
        <GridContainer
          justify="center"
        >
          <Pagination
            pages={[
              { text: 'First', value: 1 },
              page - 2 > 0 ? { text: page - 2, value: page - 2 } : { value: page },
              page - 1 > 0 ? { text: page - 1, value: page - 1 } : { value: page },
              { active: true, text: page, value: page },
              page + 1 <= totalPages ? { text: page + 1, value: page + 1 } : { value: page },
              page + 2 <= totalPages ? { text: page + 2, value: page + 2 } : { value: page },
              { text: 'Last', value: totalPages },
            ]}
            color="primary"
            clickPage={clickPage}
          />
        </GridContainer>
      </CardHeader>
      <CardBody>
        {loadingStatus == true &&
          <CircularProgress color="secondary" className='loading' />
        }
        {loadingStatus == false &&
          <Table
            tableHead={headers}
            tableData={renderDataTable}
            customHeadCellClasses={[
              classes.left,
              classes.description,
              classes.right,
              classes.right,
              classes.right,
              classes.right,
              classes.right,
              classes.right,
            ]}
            customHeadClassesForCells={[0, 1, 2, 3, 4, 5, 6, 7]}
            customCellClasses={[
              classes.customFont,
              classes.customFont,
              classes.tdNumber,
              classes.tdNumber,
              classes.tdNumber,
              classes.tdNumber,
              classes.tdNumber,
              classes.tdNumber
            ]}
            customClassesForCells={[0, 1, 2, 3, 4, 5, 6, 7]}
          />
        }
      </CardBody>
    </Card>
  );
}
