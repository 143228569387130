import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Button from "components/CustomButtons/Button.js";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";
import MenuItem from "@material-ui/core/MenuItem";
import SweetAlert from "react-bootstrap-sweetalert";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import stylesExtended from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import stylesAlert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import axios from "axios";

import '../../assets/css/style.css'

const useStyles = makeStyles(styles);
const useStylesExtended = makeStyles(stylesExtended);
const useStylesAlert = makeStyles(stylesAlert);

export default function RegularFormsPackageEdit(props) {
  const { setEditStatus, editDataId } = props
  const classes = useStyles();
  const classesExtended = useStylesExtended();
  const classesAlert = useStylesAlert();

  const [input, setInput] = useState({ product_id: '', pack_name: '', detail: '', payment_Detail: '', price1: '', pack_day: 0, p_type: '', freq_c: '', device_amount: 0 })
  const [simpleSelect, setSimpleSelect] = useState("");
  const [recurringSelect, setRecurringSelect] = useState('')
  const [simpleTypeSelect, setSimpleTypeSelect] = useState("");
  const [loadingStatus, setLoadingStatus] = useState(true)
  const [imgLoading, setImgLoading] = useState({ img_app: false, img_web: false })
  const [deleteButtonApp, setDeleteButtonApp] = useState(false)
  const [deleteButtonWeb, setDeleteButtonWeb] = useState(false)
  const [alert, setAlert] = useState(null);

  function inputChange(event) {
    const { name, value } = event.target
    setInput((prev) => {
      return {
        ...prev,
        [name]: value
      }
    })
  }

  const handleSimple = (event) => {
    setSimpleSelect(event.target.value);
    const { name, value } = event.target
    setInput((prev) => {
      return {
        ...prev,
        [name]: value
      }
    })
  };
  const handleSimpleType = (event) => {
    setSimpleTypeSelect(event.target.value);
    const { name, value } = event.target
    setInput((prev) => {
      return {
        ...prev,
        [name]: value
      }
    })
  };
  const handleRecurringType = (event) => {
    setRecurringSelect(event.target.value);
    const { name, value } = event.target
    setInput((prev) => {
      return {
        ...prev,
        [name]: value
      }
    })
  };

  //Alert+++++++++++++++++++++++++++++++
  const basicAlert = () => {
    setAlert(
      <SweetAlert
        className='card'
        style={{ display: "block", marginTop: "-100px" }}
        title="Updated!"
        onConfirm={() => window.location.reload()}
        confirmBtnCssClass={classesAlert.button + " " + classesAlert.success}
      />
    );
  };
  //Alert------------------------------
  const submitClick = (e) => {
    e.preventDefault()
    const data = new FormData()
    data.append('id', editDataId)
    data.append('pack_name', input.pack_name)
    data.append('detail', input.detail)
    data.append('payment_Detail', input.payment_Detail)
    data.append('price1', input.price1)
    data.append('freq_c', input.freq_c)
    data.append('pack_day', input.pack_day)
    data.append('p_type', input.p_type)
    data.append('device_amount', input.device_amount)
    axios.patch(`${process.env.REACT_APP_API}/data/updatepackage/id/${editDataId}`, input, {
      headers: {
        authorization: window.localStorage.getItem('userAccessToken')
      }
    }
    ).then(() => {
      basicAlert()
    })
  }
  const uploadY = (event) => {
    event.preventDefault()
    if (event.target.value !== '' && event.target.value !== null) {
      setImgLoading({ ...imgLoading, img_app: true })
      const data = new FormData()
      data.append('file', event.target.files[0])
      axios.patch(`${process.env.REACT_APP_API}/data/updateimgY/id/${editDataId}`, data, {
        headers: {
          authorization: window.localStorage.getItem('userAccessToken')
        }
      }
      ).then(() => setImgLoading({ ...imgLoading, img_app: false }))
        .finally(() => setDeleteButtonApp(true))
    }
  }
  const uploadX = (event) => {
    event.preventDefault()
    if (event.target.value !== '' && event.target.value !== null) {
      setImgLoading({ ...imgLoading, img_web: true })
      const data = new FormData()
      data.append('file', event.target.files[0])
      axios.patch(`${process.env.REACT_APP_API}/data/updateimgX/id/${editDataId}`, data, {
        headers: {
          authorization: window.localStorage.getItem('userAccessToken')
        }
      }
      ).then(() => setImgLoading({ ...imgLoading, img_web: false }))
        .finally(() => setDeleteButtonWeb(true))
    }
  }
  const deleteImgApp = (head) => {
    axios.delete(`${process.env.REACT_APP_API}/data/deleteimg/id/${editDataId}/${head}`, {
      headers: {
        authorization: window.localStorage.getItem('userAccessToken')
      }
    }
    ).then(() => setDeleteButtonApp(false))
  }
  const deleteImgWeb = (head) => {
    axios.delete(`${process.env.REACT_APP_API}/data/deleteimg/id/${editDataId}/${head}`, {
      headers: {
        authorization: window.localStorage.getItem('userAccessToken')
      }
    }
    ).then(() => setDeleteButtonWeb(false))
  }

  useEffect(() => {
    axios.post(`${process.env.REACT_APP_API}/data/package/id/${editDataId}`, {
      editDataId
    },
      {
        headers: {
          authorization: window.localStorage.getItem('userAccessToken')
        }
      }
    ).then(result => {
      setInput(result.data[0])
      result.data[0].img_app != '' && result.data[0].img_app != null ? setDeleteButtonApp(true) : setDeleteButtonApp(false)
      result.data[0].img_web != '' && result.data[0].img_web != null ? setDeleteButtonWeb(true) : setDeleteButtonWeb(false)
    }).finally(() => {
      setLoadingStatus(false)
    })
  }, [])

  return (
    <GridContainer className='card-bg'>
      <Card className='card'>
        {alert}
        {loadingStatus === false &&
          <>
            <CardHeader color="primary" text>
              <CardText color="primary">
                <h4 className={classes.cardTitle}>{input.pack_name}</h4>
                <Button color='warning' className={classes.marginRight} onClick={(e) => clickPage(e, previous[1])} block onClick={() => setEditStatus(false)}>
                  Back
                </Button>
              </CardText>
            </CardHeader>
            <CardBody>
              <form onSubmit={submitClick}>
                <GridContainer>
                  <GridItem xs={12} sm={2}>
                    <FormLabel className={classes.labelHorizontal}>
                      ชื่อแพ็คเกจ
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={10}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={11}>
                        <CustomInput
                          name='pack_name'
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            defaultValue: input.pack_name,
                          }}
                          inputChange={inputChange}
                        />
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                  <GridItem xs={12} sm={2}>
                    <FormLabel className={classes.labelHorizontal}>
                      รายละเอียด
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={10}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={11}>
                        <CustomInput
                          name='detail'
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            defaultValue: input.detail
                          }}
                          inputChange={inputChange}
                        />
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                  <GridItem xs={12} sm={2}>
                    <FormLabel className={classes.labelHorizontal}>
                      รายละเอียดการชำระ
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={10}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={11}>
                        <CustomInput
                          name='payment_Detail'
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            defaultValue: input.payment_Detail
                          }}
                          inputChange={inputChange}
                        />
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                  <GridItem xs={12} sm={2}>
                    <FormLabel className={classes.labelHorizontal}>
                      ราคา
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={10}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={11}>
                        <CustomInput
                          name='price1'
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            defaultValue: input.price1,
                            type: "number",
                            min: '0',
                          }}
                          inputChange={inputChange}
                        />
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                  <GridItem xs={12} sm={2}>
                    <FormLabel className={classes.labelHorizontal}>
                      วันหมดอายุ
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={5} lg={5}>
                    <FormControl
                      fullWidth
                      className={classesExtended.selectFormControl}
                    >
                      <InputLabel
                        htmlFor="pack_day"
                        className={classesExtended.selectLabel}
                      >
                        {input.pack_day}วัน
                      </InputLabel>
                      <Select
                        MenuProps={{
                          className: classesExtended.selectMenu,
                        }}
                        classes={{
                          select: classesExtended.select,
                        }}
                        value={simpleSelect}
                        onChange={handleSimple}
                        inputProps={{
                          name: "pack_day",
                          id: "pack_day",
                        }}
                      >
                        <MenuItem
                          classes={{
                            root: classesExtended.selectMenuItem,
                            selected: classesExtended.selectMenuItemSelected,
                          }}
                          value="1"
                        >
                          1 วัน
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classesExtended.selectMenuItem,
                            selected: classesExtended.selectMenuItemSelected,
                          }}
                          value="3"
                        >
                          3 วัน
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classesExtended.selectMenuItem,
                            selected: classesExtended.selectMenuItemSelected,
                          }}
                          value="7"
                        >
                          7 วัน / 1 สัปดาห์
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classesExtended.selectMenuItem,
                            selected: classesExtended.selectMenuItemSelected,
                          }}
                          value="10"
                        >
                          10 วัน
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classesExtended.selectMenuItem,
                            selected: classesExtended.selectMenuItemSelected,
                          }}
                          value="15"
                        >
                          15 วัน / ครี่งเดือน
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classesExtended.selectMenuItem,
                            selected: classesExtended.selectMenuItemSelected,
                          }}
                          value="30"
                        >
                          30วัน / 1 เดือน
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classesExtended.selectMenuItem,
                            selected: classesExtended.selectMenuItemSelected,
                          }}
                          value="37"
                        >
                          37วัน
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classesExtended.selectMenuItem,
                            selected: classesExtended.selectMenuItemSelected,
                          }}
                          value="90"
                        >
                          90 วัน / 3 เดือน
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classesExtended.selectMenuItem,
                            selected: classesExtended.selectMenuItemSelected,
                          }}
                          value="110"
                        >
                          110วัน
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classesExtended.selectMenuItem,
                            selected: classesExtended.selectMenuItemSelected,
                          }}
                          value="180"
                        >
                          180 วัน / 6 เดือน
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classesExtended.selectMenuItem,
                            selected: classesExtended.selectMenuItemSelected,
                          }}
                          value="365"
                        >
                          365 วัน / 1 ปี
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classesExtended.selectMenuItem,
                            selected: classesExtended.selectMenuItemSelected,
                          }}
                          value="395"
                        >
                          395วัน
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classesExtended.selectMenuItem,
                            selected: classesExtended.selectMenuItemSelected,
                          }}
                          value="410"
                        >
                          410วัน
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={2}>
                    <FormLabel className={classes.labelHorizontal}>
                      ต่ออายุอัตโนมัติ
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={5} lg={5}>
                    <FormControl
                      fullWidth
                      className={classesExtended.selectFormControl}
                    >
                      <InputLabel
                        htmlFor="freq_c"
                        className={classesExtended.selectLabel}
                      >
                        {input.freq_c == 1 ? 'Recurring' : 'One Time'}
                      </InputLabel>
                      <Select
                        MenuProps={{
                          className: classesExtended.selectMenu,
                        }}
                        classes={{
                          select: classesExtended.select,
                        }}
                        value={recurringSelect}
                        onChange={handleRecurringType}
                        inputProps={{
                          name: "freq_c",
                          id: "freq_c",
                        }}
                      >
                        <MenuItem
                          classes={{
                            root: classesExtended.selectMenuItem,
                            selected: classesExtended.selectMenuItemSelected,
                          }}
                          value="0"
                        >
                          One Time
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classesExtended.selectMenuItem,
                            selected: classesExtended.selectMenuItemSelected,
                          }}
                          value="1"
                        >
                          Recurring
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={2}>
                    <FormLabel className={classes.labelHorizontal}>
                      ประเภทของแพ็คเกจ
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={5} lg={5}>
                    <FormControl
                      fullWidth
                      className={classesExtended.selectFormControl}
                    >
                      <InputLabel
                        htmlFor="p_type"
                        className={classesExtended.selectLabel}
                      >
                        {input.p_type == 'PG' ? 'ปกติ' : input.p_type == 'RD' ? 'redeem' : 'วันพ่อ'}
                      </InputLabel>
                      <Select
                        MenuProps={{
                          className: classesExtended.selectMenu,
                        }}
                        classes={{
                          select: classesExtended.select,
                        }}
                        value={simpleTypeSelect}
                        onChange={handleSimpleType}
                        inputProps={{
                          name: "p_type",
                          id: "p_type",
                        }}
                      >
                        <MenuItem
                          classes={{
                            root: classesExtended.selectMenuItem,
                            selected: classesExtended.selectMenuItemSelected,
                          }}
                          value="PG"
                        >
                          ปกติ
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classesExtended.selectMenuItem,
                            selected: classesExtended.selectMenuItemSelected,
                          }}
                          value="RD"
                        >
                          redeem
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classesExtended.selectMenuItem,
                            selected: classesExtended.selectMenuItemSelected,
                          }}
                          value="AA"
                        >
                          วันพ่อ
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={2}>
                    <FormLabel className={classes.labelHorizontal}>
                      รูปแอป 1
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={10}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={11}>
                        <CustomInput
                          name='fileY'
                          formControlProps={{
                            fullWidth: false,
                          }}
                          inputProps={{
                            type: 'file'
                          }}
                          inputChange={uploadY}
                        />{deleteButtonApp === true &&
                          <Button color='danger' variant="text" size='sm' onClick={() => deleteImgApp('img_app')}><Close className={classes.icons} /></Button>
                        }
                        {imgLoading.img_app === true &&
                          <span>UPLOADING...</span>
                        }
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={2}>
                    <FormLabel className={classes.labelHorizontal}>
                      รูปเว็บไซต์ 1
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={10}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={11}>
                        <CustomInput
                          name='fileX'
                          formControlProps={{
                            fullWidth: false,
                          }}
                          inputProps={{
                            placeholder: "XX-XX-001",
                            type: 'file'
                          }}
                          inputChange={uploadX}
                        />
                        {deleteButtonWeb === true &&
                          <Button color='danger' variant="text" size='sm' onClick={() => deleteImgWeb('img_web')}><Close className={classes.icons} /></Button>
                        }
                        {imgLoading.img_web === true &&
                          <span>UPLOADING...</span>
                        }
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={2}>
                    <FormLabel className={classes.labelHorizontal}>
                      จำนวนเครื่องดูได้พร้อมกัน
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={10}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={11}>
                        <CustomInput
                          name='device_amount'
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            placeholder: "MVhub pack year",
                            type: "number",
                            defaultValue: input.device_amount,
                            min: '0'
                          }}
                          inputChange={inputChange}
                        />
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
                <Button type='submit' color='success' block>submit</Button>
              </form>
            </CardBody>
          </>
        }
      </Card>
    </GridContainer>
  );
}
