import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// material-ui icons
import CircularProgress from '@material-ui/core/CircularProgress';
// core components
import Table from "components/Table/Table.js";
import Badge from "components/Badge/Badge.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import { Link } from "react-router-dom";
import SweetAlertPage from "views/Components/SweetAlert";

const useStyles = makeStyles(styles);

export default function Activesub(props) {
  const { id, activePackage, sub, loadingStatus, preRender } = props
  const classes = useStyles();
  let data = []
  let subData = []
  data = activePackage.map(result => {
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const diffDays = Math.round(Math.round((new Date(result.expire_pack) - new Date()) / oneDay));
    return (
      [
        <Link to={`/admin/package/detail/${result.id}`}>{result.product_id}</Link>,
        <Link to={`/admin/package/detail/${result.id}`}>{result.pack_name}</Link>,
        result.expire_pack >= new Date().toISOString() ? <Badge color='success'>Active</Badge> : <Badge color='danger'>InActive</Badge>,
        result.balance_day,
        diffDays > 0 ? diffDays : 0,
        new Date(result.expire_pack).toLocaleString('th'),
        new Date(result.update_at).toLocaleString('th')
      ]
    )
  })

  subData = sub.map(result => {
    return (
      [
        result.expire_sub >= new Date().toISOString('th') ? <Badge color='success'>Active</Badge> : <Badge color='danger'>InActive</Badge>,
        result.sub_status == 1 ? <Badge color='success'>success</Badge> : <Badge color='warning'>Cancle</Badge>,
        new Date(result.expire_sub).toLocaleString('th'),
        new Date(result.create_at).toLocaleString('th')
      ]
    )
  })

  return (
    <>
      <Card>
        <CardHeader color="info" icon>
          <CardIcon color="info">
            Subscription
          </CardIcon>
        </CardHeader>
        <CardBody>
          {loadingStatus == true &&
            <CircularProgress color="secondary" className='loading' />
          }
          {loadingStatus == false &&
            <Table
              tableHead={['Status', 'Subscription', 'Expire Date', 'Last Payment']}
              tableData={subData}
              customCellClasses={[
                classes.left,
                classes.center,
                classes.right,
                classes.right,
              ]}
              customClassesForCells={[0, 1, 2, 3]}
              customHeadCellClasses={[
                classes.left,
                classes.center,
                classes.right,
                classes.right,
              ]}
              customHeadClassesForCells={[0, 1, 2, 3]}
            />
          }
        </CardBody>
      </Card>

      <Card>
        <CardHeader color="info" icon>
          <CardIcon color="info">
            Package
          </CardIcon>
        </CardHeader>
        <CardBody>
          {loadingStatus == true &&
            <CircularProgress color="secondary" className='loading' />
          }
          {loadingStatus == false &&
            <Table
              tableHead={['Product ID', 'Package Name', 'Status', 'วันสะสม', 'วันคงเหลือ', 'วันหมดอายุ', 'Last Update']}
              tableData={data}
              customCellClasses={[
                classes.left,
                classes.left,
                classes.left,
                classes.right,
                classes.right,
                classes.right,
                classes.right
              ]}
              customClassesForCells={[0, 1, 2, 3, 4, 5, 6]}
              customHeadCellClasses={[
                classes.left,
                classes.left,
                classes.left,
                classes.right,
                classes.right,
                classes.right,
                classes.right
              ]}
              customHeadClassesForCells={[0, 1, 2, 3, 4, 5, 6]}
            />
          }
        </CardBody>
      </Card>

      <SweetAlertPage
        id={id}
        preRender={preRender}
      />
    </>
  );
}
