import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// material-ui icons
import Assignment from "@material-ui/icons/Assignment";
import CircularProgress from '@material-ui/core/CircularProgress';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Badge from "components/Badge/Badge.js";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import { Link } from "react-router-dom";
import CardFooter from "components/Card/CardFooter";
import CustomInput from "components/CustomInput/CustomInput";
import Pagination from "components/Pagination/Pagination";

const useStyles = makeStyles(styles);

export default function ExtendedTablesPartnerForm(props) {
  const { headers, datatable, page, totalPages, clickPage, loadingStatus } = props
  const classes = useStyles();

  const getdatas = datatable.map(result => {
    console.log("zzzzzzz")
    console.log(getdatas)
    
    return (
      [
        <Link to={`/admin/searchuser/detail/${result.serial}`}>{result.user_id}</Link>,
        <Link to={`/admin/searchuser/detail/${result.user_id}`}>{result.user_id}</Link>,
        <Link to={`/admin/searchuser/detail/${result.user_id}`}>{result.username}</Link>,
        result.expire_pack >= new Date().toISOString() ? <Badge color='success'>Active</Badge> : <Badge color='danger'>Inactive</Badge>,
        result.expire_pack,
        result.user_id_old,
      ]
    )
  })
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <Assignment />
            </CardIcon>
            <GridContainer
              justify="center"
            >
              <Pagination
                pages={[
                  { text: 'First', value: 1 },
                  page - 5 > 0 ? { text: page - 5, value: page - 5 } : { value: page },
                  page - 4 > 0 ? { text: page - 4, value: page - 4 } : { value: page },
                  page - 3 > 0 ? { text: page - 3, value: page - 3 } : { value: page },
                  page - 2 > 0 ? { text: page - 2, value: page - 2 } : { value: page },
                  page - 1 > 0 ? { text: page - 1, value: page - 1 } : { value: page },
                  { active: true, text: page, value: page },
                  page + 1 <= totalPages ? { text: page + 1, value: page + 1 } : { value: page },
                  page + 2 <= totalPages ? { text: page + 2, value: page + 2 } : { value: page },
                  page + 3 <= totalPages ? { text: page + 3, value: page + 3 } : { value: page },
                  page + 4 <= totalPages ? { text: page + 4, value: page + 4 } : { value: page },
                  page + 5 <= totalPages ? { text: page + 5, value: page + 5 } : { value: page },
                  { text: 'Last', value: totalPages },
                ]}
                color="primary"
                clickPage={clickPage}
              />
            </GridContainer>
          </CardHeader>
          <CardBody>
            {/* <GridContainer
              justify="center"
            >
              <CustomInput
                labelText="Page"
                id="user_id"
                name='user_id'
                inputProps={{
                  type: "number",
                }}
                inputChange={(e) => { clickPage(e.target.value) }}
              />
            </GridContainer> */}
            {/* <div className='between'>
              <h3 className='text-left'>Page: {page}</h3>
              <CustomInput
                labelText="Page"
                id="user_id"
                name='user_id'
                inputProps={{
                  type: "number",
                }}
                inputChange={(e) => { clickPage(e.target.value) }}
              />
              <h3 className='text-right'>TotalPages: {totalPages}</h3>
            </div> */}
            <hr />
            {loadingStatus == true &&
              <CircularProgress color="secondary" className='loading' />
            }
            {loadingStatus == false &&
              <Table
                tableHead={headers}
                tableData={getdatas}
                customCellClasses={[
                  classes.left,
                  classes.left,
                  classes.left,
                  classes.center,
                  classes.center,
                  classes.right
                ]}
                customClassesForCells={[0, 1, 2, 3, 4, 5]}
                customHeadCellClasses={[
                  classes.left,
                  classes.left,
                  classes.left,
                  classes.center,
                  classes.center,
                  classes.right
                ]}
                customHeadClassesForCells={[0, 1, 2, 3, 4, 5]}
              />
            }
          </CardBody>
          <CardFooter>
          </CardFooter>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
