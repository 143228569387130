/*eslint-disable*/
import React, { useEffect, useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from '@material-ui/core/CircularProgress';

// @material-ui/icons
import Timeline from "@material-ui/icons/Timeline";
import Datetime from "react-datetime";
// core components
import Accordion from "components/Accordion/Accordion.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import ReactExport from "react-export-excel";

import styles from "assets/jss/material-dashboard-pro-react/views/chartsStyle.js";
import stylesSelect from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import axios from "axios";
import PackageCharts from "./PackageCharts";
import { Link } from "react-router-dom";

const useStyles = makeStyles(styles);
const useStylesSelect = makeStyles(stylesSelect);

export default function ChartsUsers() {
  const classes = useStyles();
  const classesSelect = useStylesSelect();
  const [input, setInput] = useState({})
  const [datas, setDatas] = useState({})
  const [limit, setLimit] = useState(21)
  const [total, setTotal] = useState(0)
  const [chartHeader, setChartHeader] = useState()
  const [chartData, setChartData] = useState()
  const [simpleSelect, setSimpleSelect] = useState('1');
  const [loading, setLoading] = useState()
  let renderTableHeader
  let Datas = []
  let Total = 0

  //excel
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
  //excel

  function dateChange(_d, name) {
    setInput((prev) => {
      return {
        ...prev,
        [name]: _d
      }
    })
  }

  const groupBy = (items, key) => items.reduce(
    (result, item) => ({
      ...result,
      [item[key]]: [
        ...(result[item[key]] || []),
        item,
      ],
    }),
    {},
  );

  const handleSimple = async (event) => {
    setSimpleSelect(event.target.value);
  };

  const clickSubmit = async (event) => {
    event.preventDefault()
    setLoading(true)
    axios.post(`${process.env.REACT_APP_API}/data/summary/users`, { input, filter: simpleSelect }, {
      headers: {
        authorization: window.localStorage.getItem('userAccessToken')
      }
    }).then(result => {
      setDatas(result.data.values)
      setChartHeader(Object.keys(groupBy(result.data.values, 'create_date')))
      for (let i of Object.values(groupBy(result.data.values, 'create_date'))) {
        Datas.push(i.length)
      }
      for (let i of Datas) {
        Total += i
      }
    })
      .finally(() => {
        setChartData([Datas])
        setTotal(Total)
        setLoading(false)
      })
  };

  if (loading === false && chartHeader.length > 0) {
    renderTableHeader = chartHeader.map((header, index) => {
      return {
        title: header,
        content: (
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <table key={index}>
                  <tbody>
                    <tr>
                      <th className='space30'><h3>User ID</h3></th><th className='space30'><h3>E-mail</h3></th><th className='space30'><h3>Tel</h3></th>
                    </tr>
                  </tbody>
                </table>
                {renderTableDetail(header, limit)}
              </Card>
              <div className='between'>
                <a className='text-click' onClick={() => setLimit(prev => prev += 10)}>load more...</a>
                <a className='text-click' onClick={() => setLimit(prev => prev -= 10)}>less...</a>
              </div>
            </GridItem>
          </GridContainer>
        )
      }
    })
  }

  function renderTableDetail(header, limit) {
    return datas.filter(data => data.create_date == header)
      .slice(0, limit)
      .map((data, index) => {
        return (
          <table key={index}>
            <tbody>
              <tr>
                <th className='space30'><Link to={`/admin/searchuser/detail/${data.user_id}`}>{data.user_id}</Link></th>
                <th className='space30'><Link to={`/admin/searchuser/detail/${data.user_id}`}>{data.email}</Link></th>
                <th className='space30'><Link to={`/admin/searchuser/detail/${data.user_id}`}>{data.user_phone}</Link></th>
              </tr>
            </tbody>
          </table>
        )
      })
  }
  useEffect(() => {
    if (!window.localStorage.getItem('userAccessToken')) {
      window.location.href = `http://${window.location.host}/auth/`
    }
    setLoading(true)
    axios.post(`${process.env.REACT_APP_API}/data/summary/users`, {
      filter: simpleSelect,
      input: {
        startdate: new Date().setDate(new Date().getDate() == 1), enddate: new Date().setMonth(new Date().getMonth(1, 0)),
      }
    }, {
      headers: {
        authorization: window.localStorage.getItem('userAccessToken')
      }
    }).then(result => {
      setDatas(result.data.values)
      setChartHeader(Object.keys(groupBy(result.data.values, 'create_date')))
      for (let i of Object.values(groupBy(result.data.values, 'create_date'))) {
        Datas.push(i.length)
      }
      for (let i of Datas) {
        Total += i
      }
    })
      .finally(() => {
        setChartData([Datas])
        setTotal(Total)
        setLoading(false)
      })
  }, [])
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <Timeline />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                ยอดการสมัครบัญชี
              </h4>
            </CardHeader>
            <CardBody>
              <form onSubmit={clickSubmit}>
                <GridContainer>
                  <GridItem sm={3} md={3} lg={3} >
                    <GridContainer
                      justify="space-between"
                      alignItems="center"
                      direction="row"
                    >
                      <Datetime
                        timeFormat={false}
                        inputProps={{ placeholder: "ตั้งแต่", required: true }}
                        name='startdate'
                        onChange={(event) => dateChange(event._d, 'startdate')}
                      />
                      <Datetime
                        timeFormat={false}
                        inputProps={{ placeholder: "จนถึง", required: true }}
                        name='enddate'
                        onChange={(event) => dateChange(event._d, 'enddate')}
                      />
                      <FormControl
                        fullWidth
                        className={classesSelect.selectFormControl}
                      >
                        <Button className={classesSelect.selectFormControl}
                          color='primary'
                          type='submit'
                        >submit</Button>
                      </FormControl>
                    </GridContainer>
                  </GridItem>

                  <GridItem sm={3} md={3} lg={3} >
                  </GridItem>

                  <GridItem sm={3} md={3} lg={3} >
                  </GridItem>

                  <GridItem sm={3} md={3} lg={3}>
                    <FormControl
                      fullWidth
                      className={classesSelect.selectFormControl}
                    >
                      <InputLabel
                        htmlFor="simple-select"
                        className={classesSelect.selectLabel}
                      >
                        Filter
                      </InputLabel>
                      <Select
                        MenuProps={{
                          className: classesSelect.selectMenu,
                        }}
                        classes={{
                          select: classesSelect.select,
                        }}
                        value={simpleSelect}
                        onChange={handleSimple}
                        inputProps={{
                          name: "simpleSelect",
                          id: "simple-select",
                        }}
                      >
                        <MenuItem
                          classes={{
                            root: classesSelect.selectMenuItem,
                            selected: classesSelect.selectMenuItemSelected,
                          }}
                          value="1"
                        >
                          รายวัน ใน เดือนปัจจุบัน
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classesSelect.selectMenuItem,
                            selected: classesSelect.selectMenuItemSelected,
                          }}
                          value="2"
                        >
                          รายเดือน ใน ปีปัจจุบัน
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </GridItem>
                </GridContainer>
                {loading === true &&
                  <CircularProgress color="secondary" className='loading' />
                }
                {loading === false &&
                  <PackageCharts
                    headers={chartHeader}
                    countsValues={chartData}
                  />
                }
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader>
              <ExcelFile element={<Button color='success'>Export</Button>}>
                <ExcelSheet data={datas} name="Package-register">
                  <ExcelColumn label="create date" value="create_date" />
                  <ExcelColumn label="User ID" value="user_id" />
                  <ExcelColumn label="E-mail" value="email" />
                  <ExcelColumn label="Tel" value="user_phone" />
                </ExcelSheet>
              </ExcelFile>
            </CardHeader>
            <CardBody>
              {loading == false && datas.length > 0 &&
                <>
                  <Accordion
                    collapses={
                      renderTableHeader
                    }
                  />
                </>
              }
              <span><h3 className='text-right'>Total: {total}</h3></span>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
