import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// material-ui icons
import Assignment from "@material-ui/icons/Assignment";
import CircularProgress from '@material-ui/core/CircularProgress';
// core components
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import { Link } from "react-router-dom";

const useStyles = makeStyles(styles);

export default function AddPackageTable(props) {
  const { id, headers, datatable, loadingStatus, addPackage, inputChange } = props
  const classes = useStyles();
  const getdatas = datatable.map(result => {
    return (
      [
        result.id,
        <Link to={`/admin/package/detail/${result.id}`}>{result.product_id}</Link>,
        <Link to={`/admin/package/detail/${result.id}`}>{result.pack_name}</Link>,
        <div className={classes.imgContainer} key="key">
          <img src={result.img_web} alt="..." className={classes.img} />
        </div>,
        result.pack_day,
        result.price1,
        <Button color="rose" onClick={() => addPackage(id, result.id, result.pack_day, result.price1, result.pack_name, result.product_id, result.device_amount)}>
          Add
        </Button>,
      ]
    )
  })
  return (
    <Card>
      <CardHeader color="info" icon>
        <CardIcon color="info">
          <Assignment />
        </CardIcon>
      </CardHeader>
      <CardBody>
        <CustomInput
          labelText={<i className="fas fa-search">Search by Pack Name</i>}
          id="username"
          name='username'
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            type: "text",
          }}
          inputChange={inputChange}
        />
        {loadingStatus == true &&
          <CircularProgress color="secondary" className='loading' />
        }
        {loadingStatus == false &&
          <Table
            tableHead={headers}
            tableData={getdatas}
            customCellClasses={[
              classes.left,
              classes.left,
              classes.left,
              classes.right,
              classes.center,
              classes.right,
              classes.center,
            ]}
            customClassesForCells={[0, 1, 2, 3, 4, 5, 6]}
            customHeadCellClasses={[
              classes.left,
              classes.left,
              classes.left,
              classes.left,
              classes.center,
              classes.right,
              classes.center,
            ]}
            customHeadClassesForCells={[0, 1, 2, 3, 4, 5, 6]}
          />
        }
      </CardBody>
    </Card>
  );
}
