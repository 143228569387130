import React, { useEffect, useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import CircularProgress from '@material-ui/core/CircularProgress';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import axios from "axios";
import HashTable from "views/Tables/HashTable";

const useStyles = makeStyles(styles);

export default function SearchHistoryPaymentForm() {
  const [input, setInput] = useState({})
  const [datas, setDatas] = useState({})
  const [loadingStatus, setLoadingStatus] = useState(false)
  const [status, setStatus] = useState(false)
  const [next, setNext] = useState([])
  const [previous, setPrevious] = useState([])

  function inputChange(event) {
    const { value, name } = event.target
    setInput((prev) => {
      return {
        ...prev,
        [name]: value
      }
    })
  }
  function submit() {
    if (Object.keys(input).length = 1) {
      setLoadingStatus(true)
      axios.post(`${process.env.REACT_APP_API}/vod/hash`, input, {
        headers: {
          authorization: window.localStorage.getItem('userAccessToken')
        }
      }).then(result => {
        if (result.data) {
          setDatas(result.data)
          result.data.next ? setNext(['primary', result.data.next.page]) : setNext('')
          result.data.previous ? setPrevious(['primary', result.data.previous.page]) : setPrevious('')
        }
      }).finally(() => {
        setLoadingStatus(false)
        setStatus(true)
      })
    }
  }
  function clickPage(e, page) {
    if (Object.keys(input).length = 1) {
      setLoadingStatus(true)
      axios.post(`${process.env.REACT_APP_API}/vod/hash`, input, {
        headers: {
          authorization: window.localStorage.getItem('userAccessToken')
        }
      }).then(result => {
        console.log(result)
        setDatas(result.data.result)
        result.data.next ? setNext(['primary', result.data.next.page]) : setNext('')
        result.data.previous ? setPrevious(['primary', result.data.previous.page]) : setPrevious('')
      }).finally(() => {
        setLoadingStatus(false)
        setStatus(true)
      })
    }
  }

  useEffect(() => {
    if (!window.localStorage.getItem('userAccessToken')) {
      window.location.href = `http://${window.location.host}/auth`
    }
  }, [])
  const classes = useStyles();
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="success" icon>
              <CardIcon color="success">
                <Face className={classes.inputAdornmentIcon} />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>ถอด Link</h4>
            </CardHeader>
            <CardBody>
              <FormControl fullWidth>
                <CustomInput
                  labelText="กรอก Username"
                  id="link"
                  name='link'
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                  }}
                  inputChange={inputChange}
                />
              </FormControl>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <Button color="success" className={classes.marginRight} block onClick={submit}>
        Search
      </Button>
      {loadingStatus == true && status == false &&
        <CircularProgress color="secondary" className='loading' />
      }
      <GridItem xs={12} sm={12} md={12}>
        {status == true && datas.length > 0 &&
          <HashTable
            loadingStatus={loadingStatus}
            headers={['ID', 'Globalname', 'Image']}
            datatable={datas}
            next={next}
            previous={previous}
            clickPage={clickPage}
          />
        }
      </GridItem>
    </div>
  );
}
