import React, { useState, useEffect } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Close from "@material-ui/icons/Close";
// core components
import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import axios from "axios";
import AddPackageTable from "../Tables/AddPackageTable";

const useStyles = makeStyles(styles);

export default function SweetAlertPage(props) {
  const classes = useStyles();
  const { id, preRender } = props
  const [loadingStatus, setLoadingStatus] = useState(true)
  const [datas, setDatas] = useState({});
  const [currentPackage, setCurrentPackage] = useState({})
  const [searchDatas, setSearchDatas] = useState({});

  const [AddPackageButton, setAddPackageButton] = useState(true)

  function inputChange(event) {
    const { value } = event.target
    setSearchDatas(datas.pack_list.filter(data => data.product_id.toLowerCase().match(value.toLowerCase())))
  }

  function addPackage(user_id, package_id, package_expire, price1, pack_name, product_id, device_amount) {
    if (window.confirm("Add Package ?")) {
      success(user_id, package_id, package_expire, price1, pack_name, product_id, device_amount)
    }
  }
  const success = (user_id, package_id, package_expire, price1, pack_name, product_id, device_amount) => {
    axios.post(`${process.env.REACT_APP_API}/form/adduserpackage`, {
      user_id, package_id, package_expire, price1, pack_name, product_id, device_amount
    },
      {
        headers: {
          authorization: window.localStorage.getItem('userAccessToken')
        }
      }
    ).then(() => {
      preRender()
      setCurrentPackage([{ pack_name: pack_name }])
    })
  }
  const clearPackage = () => {
    if (window.confirm("ลบแพ็คเกจปัจจุบัน ?")) {
      clear()
    }
  }
  const clear = () => {
    axios.post(`${process.env.REACT_APP_API}/form/clearuserpackage`, {
      user_id: id, package_id: 0, package_expire: 0, price1: 0, pack_name: 'DeletePackage', device_amount: 0
    },
      {
        headers: {
          authorization: window.localStorage.getItem('userAccessToken')
        }
      }
    ).then(() => {
      // preRender()
      location.reload()
    })
  }

  useEffect(() => {
    axios.post(`${process.env.REACT_APP_API}/data/package/?id=${id}`, 'index', {
      headers: {
        authorization: window.localStorage.getItem('userAccessToken')
      }
    }).then(result => {
      setDatas(result.data)
      setCurrentPackage(result.data.result)
      setSearchDatas(result.data.pack_list)
    }).finally(() => setLoadingStatus(false))
  }, [])
  return (
    <>
      {currentPackage.length == 1 &&
        <>
          <Button color='danger' variant="text" size='sm' onClick={clearPackage} block><Close className={classes.icons} />Delete Current Package</Button>
        </>
      }
      {loadingStatus === false &&
        AddPackageButton == false &&
        <AddPackageTable
          loadingStatus={loadingStatus}
          headers={['ID', 'Globalname', 'Packname', 'Img', 'Expire Date', 'Price', 'Action']}
          datatable={searchDatas}
          addPackage={addPackage}
          id={id}
          inputChange={inputChange}
        />
        ||
        AddPackageButton == true &&
        <Button color='info' variant="text" size='sm' onClick={() => setAddPackageButton(false)} block>Manual Add Package</Button>
      }
    </>
  );
}
