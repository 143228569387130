import React, { useState, useEffect } from "react";
// @material-ui/core components
import CircularProgress from '@material-ui/core/CircularProgress';

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import NavPills from "components/NavPills/NavPills.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import axios from "axios";
import {
    useParams
} from "react-router-dom";

import '../../assets/css/style.css'
import PackageUsageHistoryTable from "views/Tables/PackageUsageHistoryTable";

export default function PackageDetail() {
    let { id } = useParams()
    const [datas, setDatas] = useState({})
    const [userDatas, setUserDatas] = useState({})
    const [loadingStatus, setLoadingStatus] = useState(true)
    const [input, setInput] = useState({ userType: 'all' })
    const [page, setPage] = useState(1)
    const [totalPages, setTotalPages] = useState()

    const clickPage = (page) => {
        axios.post(`${process.env.REACT_APP_API}/data/packageusage/${id}?page=${page}&type=${input.userType}`, { name: name }, {
            headers: {
                authorization: window.localStorage.getItem('userAccessToken')
            }
        }).then(result => {
            setUserDatas(result.data.result)
            setTotalPages(result.data.totalPages)
            setPage(page)
        })
    }
    function filterPackageHistory(type) {
        axios.post(`${process.env.REACT_APP_API}/data/packageusage/${id}?page=${page}&type=${type}`, 'index', {
            headers: {
                authorization: window.localStorage.getItem('userAccessToken')
            }
        }).then(result => {
            setUserDatas(result.data.result)
            setTotalPages(result.data.totalPages)
            setPage(page)
            setInput({ userType: type })
        })
    }
    useEffect(() => {
        if (!window.localStorage.getItem('userAccessToken')) {
            window.location.href = `http://${window.location.host}/auth/`
        }
        //package index
        axios.post(`${process.env.REACT_APP_API}/data/packagedetail/${id}`, 'index', {
            headers: {
                authorization: window.localStorage.getItem('userAccessToken')
            }
        }).then(result => {
            console.log(result)
            setDatas(result.data)
            axios.post(`${process.env.REACT_APP_API}/data/packageusage/${id}?type=${input.userType}`, { name: name }, {
                headers: {
                    authorization: window.localStorage.getItem('userAccessToken')
                }
            }).then(result => {
                setUserDatas(result.data.result)
                setTotalPages(result.data.totalPages)
                setPage(1)
            })
        }).finally(() => setLoadingStatus(false))
    }, [])
    return (
        <>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <NavPills
                        color="rose"
                        active={0}
                        tabs={[
                            {
                                tabButton: "INFO",
                                tabContent: (
                                    <span>
                                        {loadingStatus === true &&
                                            <CircularProgress color="secondary" className='loading' />
                                        }
                                        {loadingStatus === false && datas.length > 0 &&
                                            <>
                                                <GridContainer>
                                                    <GridItem sm={4} md={4}>
                                                        <Card>
                                                            <CardHeader color='primary'>
                                                                <h4>Product ID | รหัสแพ็กเกจ</h4>
                                                            </CardHeader>
                                                            <CardBody>
                                                                <h4>{datas[0].product_id}</h4>
                                                            </CardBody>
                                                        </Card>
                                                    </GridItem>
                                                    <GridItem sm={4} md={4}>
                                                        <Card>
                                                            <CardHeader color='success'>
                                                                <h4>Package Name | ชื่อแพ็กเกจ</h4>
                                                            </CardHeader>
                                                            <CardBody>
                                                                <h4>{datas[0].pack_name}</h4>
                                                            </CardBody>
                                                        </Card>
                                                    </GridItem>
                                                    <GridItem sm={2} md={2}>
                                                        <Card>
                                                            <CardHeader color='danger'>
                                                                <h4>Expire | วันหมดอายุ</h4>
                                                            </CardHeader>
                                                            <CardBody>
                                                                <h4>{datas[0].pack_day}วัน</h4>
                                                            </CardBody>
                                                        </Card>
                                                    </GridItem>
                                                    <GridItem sm={2} md={2}>
                                                        <Card>
                                                            <CardHeader color='info'>
                                                                <h4>Price | ราคา</h4>
                                                            </CardHeader>
                                                            <CardBody>
                                                                <h4>{datas[0].price1}บาท</h4>
                                                            </CardBody>
                                                        </Card>
                                                    </GridItem>
                                                </GridContainer>
                                                <GridContainer>
                                                    <GridItem sm={12} md={12}>
                                                        <Card>
                                                            <CardHeader color='default'>
                                                                <h4>Detail | รายละเอียด</h4>
                                                                <hr />
                                                            </CardHeader>
                                                            <CardBody>
                                                                {datas[0].detail}
                                                            </CardBody>
                                                        </Card>
                                                    </GridItem>
                                                    <GridItem sm={12} md={12}>
                                                        <Card>
                                                            <CardHeader>
                                                                <h4>Payment Detail | รายละเอียดการจ่ายเงิน</h4>
                                                                <hr />
                                                            </CardHeader>
                                                            <CardBody>
                                                                {datas[0].payment_Detail}
                                                            </CardBody>
                                                        </Card>
                                                    </GridItem>
                                                </GridContainer>
                                                <GridContainer>
                                                    <GridItem sm={6} md={6}>
                                                        <Card>
                                                            <CardHeader>
                                                                <h4>Image App | รูปในโทรศัพท์</h4>
                                                            </CardHeader>
                                                            <img src={datas[0].img_app} alt="..." className='img text-click' onClick={() => location.href = `${datas[0].img_app}`} />
                                                        </Card>
                                                    </GridItem>
                                                    <GridItem sm={6} md={6}>
                                                        <Card>
                                                            <CardHeader>
                                                                <h4>Image Web | รูปในเว็บ</h4>
                                                            </CardHeader>
                                                            <img src={datas[0].img_web} alt="..." className='img text-click' onClick={() => location.href = `${datas[0].img_web}`} />
                                                        </Card>
                                                    </GridItem>
                                                </GridContainer>
                                            </>
                                        }
                                    </span>
                                ),
                            },
                            {
                                tabButton: "PACKAGE USAGE HISTORY",
                                tabContent: (
                                    <>
                                        {loadingStatus == false &&
                                            <PackageUsageHistoryTable
                                                loadingStatus={loadingStatus}
                                                headers={['User ID', 'Reference No.', 'Transaction Date']}
                                                datatable={userDatas}
                                                input={input}
                                                filterPackageHistory={filterPackageHistory}
                                                page={page}
                                                totalPages={totalPages}
                                                clickPage={clickPage}
                                            />
                                        }
                                    </>
                                ),
                            },
                        ]}
                    />
                </GridItem>
            </GridContainer>
        </>
    );
}
