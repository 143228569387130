import React, { useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// material-ui icons
import Assignment from "@material-ui/icons/Assignment";
import CircularProgress from '@material-ui/core/CircularProgress';
// core components
import Button from "components/CustomButtons/Button.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import ReactExport from "react-export-excel";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import { Link } from "react-router-dom";
import Pagination from "components/Pagination/Pagination";
import GridContainer from "components/Grid/GridContainer";
import { FormControlLabel, Radio } from "@material-ui/core";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";

const useStyles = makeStyles(styles);

export default function PackageUsageHistoryTable(props) {
  const { headers, datatable, input, filterPackageHistory, page, totalPages, clickPage, loadingStatus } = props
  const classes = useStyles();

  //excel
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
  //excel

  let renderDataTable
  if (datatable.length > 0) {
    renderDataTable = datatable.map(data => {
      return (
        [
          <p>
            <Link to={`/admin/searchuser/detail/${data.user_id}`}>
              {data.user_id}
            </Link>
          </p>,
          // data.expire_pack >= new Date().toISOString() ? <Badge color='success'>Active</Badge> : <Badge color='danger'>InActive</Badge>
          // ,
          <span>
            {data.referenceNo}
            <br />
          </span>,
          <p>
            {new Date(data.create_at).toLocaleString('th')}
          </p>,
        ]
      )
    })
  }
  return (
    <Card>
      <CardHeader color="success" icon>
        <CardIcon color="success">
          <Assignment />
        </CardIcon>
        <FormControlLabel
          control={
            <Radio
              checked={input.userType === "all"}
              onChange={(event) => filterPackageHistory(event.target.value)}
              value="all"
              name="userType"
              aria-label="ALL"
              icon={
                <FiberManualRecord
                  className={classes.radioUnchecked}
                />
              }
              checkedIcon={
                <FiberManualRecord
                  className={classes.radioChecked}
                />
              }
              classes={{
                checked: classes.radio,
                root: classes.radioRoot,
              }}
            />
          }
          classes={{
            label: classes.label,
            root: classes.labelRoot,
          }}
          label="All"
        />
        <FormControlLabel
          control={
            <Radio
              checked={input.userType === "active"}
              onChange={(event) => filterPackageHistory(event.target.value)}
              value="active"
              name="userType"
              aria-label="active"
              icon={
                <FiberManualRecord
                  className={classes.radioUnchecked}
                />
              }
              checkedIcon={
                <FiberManualRecord
                  className={classes.radioChecked}
                />
              }
              classes={{
                checked: classes.radio,
                root: classes.radioRoot,
              }}
            />
          }
          classes={{
            label: classes.label,
            root: classes.labelRoot,
          }}
          label="Indate"
        />
        <FormControlLabel
          control={
            <Radio
              checked={input.userType === "inactive"}
              onChange={(event) => filterPackageHistory(event.target.value)}
              value="inactive"
              name="userType"
              aria-label="inactive"
              icon={
                <FiberManualRecord
                  className={classes.radioUnchecked}
                />
              }
              checkedIcon={
                <FiberManualRecord
                  className={classes.radioChecked}
                />
              }
              classes={{
                checked: classes.radio,
                root: classes.radioRoot,
              }}
            />
          }
          classes={{
            label: classes.label,
            root: classes.labelRoot,
          }}
          label="Out of date"
        />
        <ExcelFile element={<Button color='success'>Export</Button>}>
          <ExcelSheet data={datatable} name="Package-register-counts">
            <ExcelColumn label="user_id" value="user_id" />
            <ExcelColumn label="referenceNo" value="referenceNo" />
            <ExcelColumn label="create_at" value="create_at" />
          </ExcelSheet>
        </ExcelFile>
        <GridContainer
          justify="center"
        >
          <Pagination
            pages={[
              { text: 'First', value: 1 },
              page - 2 > 0 ? { text: page - 2, value: page - 2 } : { value: page },
              page - 1 > 0 ? { text: page - 1, value: page - 1 } : { value: page },
              { active: true, text: page, value: page },
              page + 1 <= totalPages ? { text: page + 1, value: page + 1 } : { value: page },
              page + 2 <= totalPages ? { text: page + 2, value: page + 2 } : { value: page },
              { text: 'Last', value: totalPages },
            ]}
            color="primary"
            clickPage={clickPage}
          />
        </GridContainer>
      </CardHeader>
      <CardBody>
        {loadingStatus == true &&
          <CircularProgress color="secondary" className='loading' />
        }
        {loadingStatus == false && datatable.length > 0 &&
          <Table
            tableHead={headers}
            tableData={renderDataTable}
            customHeadCellClasses={[
              classes.left,
              classes.left,
              classes.right,
              classes.right,
              classes.right,
              classes.right,
              classes.right,
            ]}
            customHeadClassesForCells={[0, 1, 2, 3, 4, 5, 6]}
            customCellClasses={[
              classes.customFont,
              classes.left,
              classes.tdNumber,
              classes.tdNumber,
              classes.tdNumber,
              classes.tdNumber,
              classes.tdNumber
            ]}
            customClassesForCells={[0, 1, 2, 3, 4, 5, 6]}
          />
        }
      </CardBody>
    </Card>
  );
}
