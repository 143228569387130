import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";

// material-ui icons
import Assignment from "@material-ui/icons/Assignment";
import CircularProgress from '@material-ui/core/CircularProgress';
// core components
import Button from "components/CustomButtons/Button.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Close from "@material-ui/icons/Close";
import GridContainer from "components/Grid/GridContainer";
import Pagination from "components/Pagination/Pagination";
import { Link } from "react-router-dom";
import axios from "axios";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

const useStyles = makeStyles(styles);

export default function ManualHistoryTable(props) {
  const { id } = props
  const [loadingStatus, setLoadingStatus] = useState(true)
  const [datas, setDatas] = useState({});
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState()
  const classes = useStyles();
  let data = []
  if (datas.length > 0) {
    data = datas.map(result => {
      if (Date.now() < new Date(result.expire_pack)) {
        return (
          [
            <p>{result.referenceNo}</p>,
            <Link to={`/admin/package/detail/${result.package_id}`}><p>{result.good_name}</p></Link>,
            // result.res_cd == '00' ? <Badge color='success'>success</Badge> : <Badge color='danger'>Fail</Badge>,
            <p>{new Date(result.create_at).toLocaleString('th')}</p>,
            result.card_no
          ]
        )
      } else {
        return (
          [
            <p>{result.referenceNo}</p>,
            <Link to={`/admin/package/detail/${result.package_id}`}><p>{result.good_name}</p></Link>,
            // result.res_cd == '00' ? <Badge color='success'>success</Badge> : <Badge color='danger'>Fail</Badge>,
            <p>{new Date(result.create_at).toLocaleString('th')}</p>,
            result.card_no
          ]
        )
      }
    })
  }
  const clickPage = (page) => {
    axios.post(`${process.env.REACT_APP_API}/form/manualhistory?id=${id}&page=${page}`, { username: id }, {
      headers: {
        authorization: window.localStorage.getItem('userAccessToken')
      }
    }).then(result => {
      setDatas(result.data.result)
      setTotalPages(result.data.totalPages)
      setPage(page)
    }).finally(() => setLoadingStatus(false))
  }
  function deletePackFromHistory(package_id, referenceNo) {
    if (confirm('ลบแพ็คเกจของลูกค้า?') === true)
      if (confirm('!โปรดมั่นใจว่าจะลบแพ็คเกจของลูกค้า!') === true)
        axios.post(`${process.env.REACT_APP_API}/form/deletepackagefromhistory`, {
          id,
          package_id,
          referenceNo
        }, {
          headers: {
            authorization: window.localStorage.getItem('userAccessToken')
          }
        }).then(() => loadData())
  }
  function loadData() {
    axios.post(`${process.env.REACT_APP_API}/form/manualhistory?id=${id}`, { username: id }, {
      headers: {
        authorization: window.localStorage.getItem('userAccessToken')
      }
    }).then(result => {
      setDatas(result.data.result)
      setTotalPages(result.data.totalPages)
      setPage(1)
    }).finally(() => setLoadingStatus(false))
  }
  useEffect(() => {
    loadData()
  }, [])
  return (
    <>
      <Card>
        <CardHeader color="rose" icon>
          <CardIcon color="rose">
            <Assignment />
          </CardIcon>
        </CardHeader>
        <CardBody>
          {loadingStatus == true &&
            <CircularProgress color="secondary" className='loading' />
          }
          {loadingStatus == false &&
            <>
              <GridContainer
                justify="center"
              >
                <Pagination
                  pages={[
                    { text: 'First', value: 1 },
                    page - 2 > 0 ? { text: page - 2, value: page - 2 } : { value: page },
                    page - 1 > 0 ? { text: page - 1, value: page - 1 } : { value: page },
                    { active: true, text: page, value: page },
                    page + 1 <= totalPages ? { text: page + 1, value: page + 1 } : { value: page },
                    page + 2 <= totalPages ? { text: page + 2, value: page + 2 } : { value: page },
                    { text: 'Last', value: totalPages },
                  ]}
                  color="primary"
                  clickPage={clickPage}
                />
              </GridContainer>
              <Table
                tableHead={['Ref No.', 'Package', 'Transaction Date', 'ID']}
                tableData={data}
                customCellClasses={[
                  classes.left,
                  classes.left,
                  classes.center,
                  classes.right,
                  classes.right
                ]}
                customClassesForCells={[0, 1, 2, 3, 4]}
                customHeadCellClasses={[
                  classes.left,
                  classes.left,
                  classes.center,
                  classes.right,
                  classes.right
                ]}
                customHeadClassesForCells={[0, 1, 2, 3, 4]}
              />
            </>
          }
        </CardBody>
      </Card>
    </>
  );
}
