import React, { useState, useEffect, useRef } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from '@material-ui/core/CircularProgress';
// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import NavPills from "components/NavPills/NavPills.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import axios from "axios";
import RegularFormsPackageEdit from "views/Forms/RegularFormsPackageEdit";
import NA_PackageListTable from "../NotAdminTables/NA_PackageListTable";
import '../../assets/css/style.css'
import { Switch, Route } from "react-router-dom";
import PackageDetail from "../Pages/PackageDetail";

const useStyles = makeStyles(styles);

export default function NA_Package() {
    const [datas, setDatas] = useState({})
    const [radioBut, SetRadioBut] = useState('a')
    const [searchDatas, setSearchDatas] = useState({})
    const [loadingStatus, setLoadingStatus] = useState(true)
    const [editStatus, setEditStatus] = useState(false)
    const [editDataId, setEditDataId] = useState()
    const classes = useStyles();
    const searchInput = useRef('');
    // const [counts, setCounts] = useState({})
    // const [resultCounts, setResultCounts] = useState({})
    // const [next, setNext] = useState([])
    // const [previous, setPrevious] = useState([])

    function radioChange(event) {
        SetRadioBut(event.target.value)
        if (event.target.value == 'a') {
            setSearchDatas(datas.filter(data => data.product_id.toLowerCase().match(searchInput.current.toLowerCase())))
        }
        if (event.target.value == 'p') {
            setSearchDatas(datas.filter(data => data.product_id.toLowerCase().match(searchInput.current.toLowerCase()) && data.status == 1))
        }
        if (event.target.value == 'u') {
            setSearchDatas(datas.filter(data => data.product_id.toLowerCase().match(searchInput.current.toLowerCase()) && data.status == 0))
        }
    }

    function searchChange(event) {
        searchInput.current = event.target.value
        setSearchDatas(datas.filter(data => data.product_id.toLowerCase().match(searchInput.current.toLowerCase())))
    }
    const clickDelete = (id) => {
        setDatas(datas.filter(data => data.id != id))
        setSearchDatas(datas.filter(data => data.id != id))
    }

    const setPackageStatus = (id, status) => {
        axios.post(`${process.env.REACT_APP_API}/data/packagestatus/${id}/${status}`, { name: '' }, {
            headers: {
                authorization: window.localStorage.getItem('userAccessToken')
            }
        }).then(() => {
            setDatas(
                datas.map((data => {
                    return data.id == id
                        ? {
                            id: id,
                            product_id: data.product_id,
                            pack_name: data.pack_name,
                            img_web: data.img_web,
                            pack_day: data.pack_day,
                            package_id: data.package_id,
                            price1: data.price1,
                            create_at: data.create_at,
                            freq_c: data.freq_c,
                            status: status
                        }
                        : data
                }))
            )
        }).finally(() => {
            setSearchDatas(
                datas.map((data => {
                    return data.id == id
                        ? {
                            id: id,
                            product_id: data.product_id,
                            pack_name: data.pack_name,
                            img_web: data.img_web,
                            pack_day: data.pack_day,
                            package_id: data.package_id,
                            price1: data.price1,
                            create_at: data.create_at,
                            freq_c: data.freq_c,
                            status: status
                        }
                        : data
                }))
            )
        })
    }

    // const clickPage = (page) => {
    //     axios.post(`${process.env.REACT_APP_API}/data/countpackage?page=${page}`, { name: name }, {
    //         headers: {
    //             authorization: window.localStorage.getItem('userAccessToken')
    //         }
    //     }).then(result => {
    //         result.data.next ? setNext(['primary', result.data.next.page]) : setNext('')
    //         result.data.previous ? setPrevious(['primary', result.data.previous.page]) : setPrevious('')
    //     })
    // }

    useEffect(() => {
        if (!window.localStorage.getItem('userAccessToken')) {
            window.location.href = `http://${window.location.host}/auth/`
        }
        //package index
        axios.post(`${process.env.REACT_APP_API}/data/package`, { name: name }, {
            headers: {
                authorization: window.localStorage.getItem('userAccessToken')
            }
        }).then(result => {
            setDatas(result.data.pack_list)
            setSearchDatas(result.data.pack_list)
        }).finally(() => setLoadingStatus(false))
        //packagecount
        // axios.post(`${process.env.REACT_APP_API}/data/countpackage`, { name: name }, {
        //     headers: {
        //         authorization: window.localStorage.getItem('userAccessToken')
        //     }
        // }).then(result => {
        //     setCounts(result.data.pack_list)
        //     setResultCounts(result.data.pack_list)
        // })
    }, [])
    return (
        <>
            <Switch>
                <Route path='/admin/package/detail/:id' component={PackageDetail} exact />
                <Route path='/admin/package' exact>
                    {editStatus === true &&
                        <div id='edit'>
                            <RegularFormsPackageEdit
                                setEditStatus={setEditStatus}
                                editDataId={editDataId}
                            />
                        </div>
                    }
                    {editStatus === false &&
                        <GridContainer justify='center'>
                            <GridItem xs={12} sm={12} md={12}>
                                <NavPills
                                    color="rose"
                                    tabs={[
                                        {
                                            tabButton: "PACKAGE LIST",
                                            tabContent: (
                                                <span>
                                                    {loadingStatus === true &&
                                                        <CircularProgress color="secondary" className='loading' />
                                                    }
                                                    {loadingStatus === false && datas.length > 0 &&
                                                        <>
                                                            <Card>
                                                                <CardBody>
                                                                    <CustomInput
                                                                        ref={searchInput}
                                                                        labelText={<i className="fas fa-search">Search by Pack Name</i>}
                                                                        id="username"
                                                                        name='username'
                                                                        formControlProps={{
                                                                            fullWidth: true,
                                                                        }}
                                                                        inputProps={{
                                                                            type: "text",
                                                                        }}
                                                                        inputChange={searchChange}
                                                                    />
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Radio
                                                                                checked={radioBut === 'a'}
                                                                                onChange={radioChange}
                                                                                value="a"
                                                                                name="userType"
                                                                                aria-label="A"
                                                                                icon={
                                                                                    <FiberManualRecord
                                                                                        className={classes.radioUnchecked}
                                                                                    />
                                                                                }
                                                                                checkedIcon={
                                                                                    <FiberManualRecord
                                                                                        className={classes.radioChecked}
                                                                                    />
                                                                                }
                                                                                classes={{
                                                                                    checked: classes.radio,
                                                                                    root: classes.radioRoot,
                                                                                }}
                                                                            />
                                                                        }
                                                                        classes={{
                                                                            label: classes.label,
                                                                            root: classes.labelRoot,
                                                                        }}
                                                                        label="All"
                                                                    />
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Radio
                                                                                checked={radioBut === 'p'}
                                                                                onChange={radioChange}
                                                                                value="p"
                                                                                name="userType"
                                                                                aria-label="A"
                                                                                icon={
                                                                                    <FiberManualRecord
                                                                                        className={classes.radioUnchecked}
                                                                                    />
                                                                                }
                                                                                checkedIcon={
                                                                                    <FiberManualRecord
                                                                                        className={classes.radioChecked}
                                                                                    />
                                                                                }
                                                                                classes={{
                                                                                    checked: classes.radio,
                                                                                    root: classes.radioRoot,
                                                                                }}
                                                                            />
                                                                        }
                                                                        classes={{
                                                                            label: classes.label,
                                                                            root: classes.labelRoot,
                                                                        }}
                                                                        label="Publish"
                                                                    />
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Radio
                                                                                checked={radioBut === 'u'}
                                                                                onChange={radioChange}
                                                                                value="u"
                                                                                name="userType"
                                                                                aria-label="A"
                                                                                icon={
                                                                                    <FiberManualRecord
                                                                                        className={classes.radioUnchecked}
                                                                                    />
                                                                                }
                                                                                checkedIcon={
                                                                                    <FiberManualRecord
                                                                                        className={classes.radioChecked}
                                                                                    />
                                                                                }
                                                                                classes={{
                                                                                    checked: classes.radio,
                                                                                    root: classes.radioRoot,
                                                                                }}
                                                                            />
                                                                        }
                                                                        classes={{
                                                                            label: classes.label,
                                                                            root: classes.labelRoot,
                                                                        }}
                                                                        label="UnPublish"
                                                                    />
                                                                </CardBody>
                                                            </Card>
                                                            <NA_PackageListTable
                                                                loadingStatus={loadingStatus}
                                                                headers={['ID', 'Product ID', 'Pack Name', 'Image', 'Expire Date', 'Price', 'Type', 'Publish']}
                                                                datatable={searchDatas}
                                                                clickDelete={clickDelete}
                                                                setEditStatus={setEditStatus}
                                                                setEditDataId={setEditDataId}
                                                                setPackageStatus={setPackageStatus}
                                                            />
                                                        </>
                                                    }
                                                </span>
                                            ),
                                        },
                                    ]}
                                />
                            </GridItem>
                        </GridContainer>
                    }
                </Route>
            </Switch>
        </>
    );
}