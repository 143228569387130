import React, { useEffect } from 'react'

export default function LogoutPage() {
    useEffect(() => {
        window.localStorage.removeItem('userAccessToken');
        window.location.href = '/auth'
    }, [])
    return (
        <div>

        </div>
    )
}
