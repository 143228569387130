import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import FormControl from "@material-ui/core/FormControl";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import stylesExtended from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import '../../assets/css/style.css'

const useStylesExtended = makeStyles(stylesExtended);

export default function InputForm(props) {
    const { inputChange, input } = props
    const classesExtended = useStylesExtended();

    return (
        <GridContainer>
            {/* <GridItem xs={12} sm={12} md={12}>
                <FormControl
                    fullWidth
                    className={classesExtended.selectFormControl}
                >
                    <InputLabel
                        htmlFor="user_id"
                        className={classesExtended.selectLabel}
                    >
                        user-ID / username / e-mail / tel
                    </InputLabel>
                </FormControl>
                <CustomInput
                    // labelText="ช่องค้นหา"
                    id="user_id"
                    name='user_id'
                    formControlProps={{
                        fullWidth: true,
                    }}
                    inputProps={{
                        type: "text",
                    }}
                    inputChange={inputChange}
                />
            </GridItem> */}
            <GridItem xs={12} sm={12} md={3}>
                <FormControl
                    fullWidth
                    className={classesExtended.selectFormControl}
                >
                    <InputLabel
                        htmlFor="user_id"
                        className={classesExtended.selectLabel}
                    >
                        user-id
                    </InputLabel>
                </FormControl>
                <CustomInput
                    // labelText="ช่องค้นหา"
                    id="user_id"
                    name='user_id'
                    formControlProps={{
                        fullWidth: true,
                    }}
                    inputProps={{
                        type: "text",
                        defaultValue: input.user_id
                    }}
                    inputChange={inputChange}
                />
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
                <FormControl
                    fullWidth
                    className={classesExtended.selectFormControl}
                >
                    <InputLabel
                        htmlFor="username"
                        className={classesExtended.selectLabel}
                    >
                        Username
                    </InputLabel>
                </FormControl>
                <CustomInput
                    // labelText="ช่องค้นหา"
                    id="username"
                    name='username'
                    formControlProps={{
                        fullWidth: true,
                    }}
                    inputProps={{
                        type: "text",
                        defaultValue: input.username
                    }}
                    inputChange={inputChange}
                />
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
                <FormControl
                    fullWidth
                    className={classesExtended.selectFormControl}
                >
                    <InputLabel
                        htmlFor="email"
                        className={classesExtended.selectLabel}
                    >
                        E-mail
                    </InputLabel>
                </FormControl>
                <CustomInput
                    // labelText="ช่องค้นหา"
                    id="email"
                    name='email'
                    formControlProps={{
                        fullWidth: true,
                    }}
                    inputProps={{
                        type: "text",
                        defaultValue: input.email
                    }}
                    inputChange={inputChange}
                />
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
                <FormControl
                    fullWidth
                    className={classesExtended.selectFormControl}
                >
                    <InputLabel
                        htmlFor="user_phone"
                        className={classesExtended.selectLabel}
                    >
                        Tel
                    </InputLabel>
                </FormControl>
                <CustomInput
                    // labelText="ช่องค้นหา"
                    id="user_phone"
                    name='user_phone'
                    formControlProps={{
                        fullWidth: true,
                    }}
                    inputProps={{
                        type: "text",
                        defaultValue: input.user_phone
                    }}
                    inputChange={inputChange}
                />
            </GridItem>
        </GridContainer>
    )
}
