import React, { useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import stylesExtended from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import axios from "axios";

const useStyles = makeStyles(styles);
const useStylesExtended = makeStyles(stylesExtended);

export default function AddPackageForm() {
  const classes = useStyles();
  const classesExtended = useStylesExtended();

  const [input, setInput] = useState({ product_id: '', pack_name: '', detail: '', payment_Detail: '', price1: '', pack_day: '', p_type: '', freq_c: '', device_amount: 0 })
  const [simpleSelect, setSimpleSelect] = useState("");
  const [recurringSelect, setRecurringSelect] = useState('')
  const [simpleTypeSelect, setSimpleTypeSelect] = useState("");

  function inputChange(event) {
    const { name, value } = event.target
    setInput((prev) => {
      return {
        ...prev,
        [name]: value
      }
    })
  }

  const handleSimple = (event) => {
    setSimpleSelect(event.target.value);
    const { name, value } = event.target
    setInput((prev) => {
      return {
        ...prev,
        [name]: value
      }
    })
  };
  const handleSimpleType = (event) => {
    setSimpleTypeSelect(event.target.value);
    const { name, value } = event.target
    setInput((prev) => {
      return {
        ...prev,
        [name]: value
      }
    })
  };
  const handleRecurringType = (event) => {
    setRecurringSelect(event.target.value);
    const { name, value } = event.target
    setInput((prev) => {
      return {
        ...prev,
        [name]: value,
        pack_day: 0
      }
    })
    setSimpleSelect('')
  };

  const submitClick = (e) => {
    e.preventDefault()
    const data = new FormData()
    data.append('product_id', input.product_id)
    data.append('pack_name', input.pack_name)
    data.append('detail', input.detail)
    data.append('payment_Detail', input.payment_Detail)
    data.append('price1', input.price1)
    data.append('freq_c', input.freq_c)
    data.append('pack_day', input.pack_day)
    data.append('p_type', input.p_type)
    data.append('device_amount', input.device_amount)
    data.append('file', input.fileY)
    data.append('file', input.fileX)
    axios.post(`${process.env.REACT_APP_API}/data/createpackage`, data, {
      headers: {
        authorization: window.localStorage.getItem('userAccessToken')
      }
    }, {
      contentType: 'multiport/form-data'
    }
    ).then(() => window.location.reload())
  }
  return (
    <GridContainer>
      <Card>
        <CardHeader color="primary" text>
        </CardHeader>
        <CardBody>
          <form onSubmit={submitClick}>
            <GridContainer>
              <GridItem xs={12} sm={2}>
                <FormLabel className={classes.labelHorizontal}>
                  รหัสแพ็กเกจ
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={10}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={11}>
                    <CustomInput
                      name='product_id'
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        placeholder: "PD-001",
                        required: true
                      }}
                      inputChange={inputChange}
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem xs={12} sm={2}>
                <FormLabel className={classes.labelHorizontal}>
                  ชื่อแพ็กเกจ
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={10}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={11}>
                    <CustomInput
                      name='pack_name'
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        placeholder: "MVhub pack year",
                        required: true
                      }}
                      inputChange={inputChange}
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem xs={12} sm={2}>
                <FormLabel className={classes.labelHorizontal}>
                  รายละเอียด
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={10}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={11}>
                    <CustomInput
                      name='detail'
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        placeholder: "หนังและซีรีส์จีนพากย์เสียงไทย",
                      }}
                      inputChange={inputChange}
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem xs={12} sm={2}>
                <FormLabel className={classes.labelHorizontal}>
                  รายละเอียดการชำระ
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={10}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={11}>
                    <CustomInput
                      name='payment_Detail'
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        placeholder: "ชำระผ่านบัตรเครดิต บัตรเดบิต",
                      }}
                      inputChange={inputChange}
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>

              <GridContainer>
                <GridItem xs={12} sm={2}>
                  <FormLabel className={classes.labelHorizontal}>
                    ต่ออายุอัตโนมัติ
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={6} md={5} lg={5}>
                  <FormControl
                    fullWidth
                    className={classesExtended.selectFormControl}
                  >
                    <InputLabel
                      htmlFor="freq_c"
                      className={classesExtended.selectLabel}
                    >
                      One time / Recurring
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classesExtended.selectMenu,
                      }}
                      classes={{
                        select: classesExtended.select,
                      }}
                      value={recurringSelect}
                      onChange={handleRecurringType}
                      inputProps={{
                        name: "freq_c",
                        id: "freq_c",
                        required: true
                      }}
                    >
                      <MenuItem
                        classes={{
                          root: classesExtended.selectMenuItem,
                          selected: classesExtended.selectMenuItemSelected,
                        }}
                        value="0"
                      >
                        One Time
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classesExtended.selectMenuItem,
                          selected: classesExtended.selectMenuItemSelected,
                        }}
                        value="1"
                      >
                        Recurring
                      </MenuItem>
                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>

              <GridItem xs={12} sm={2}>
                <FormLabel className={classes.labelHorizontal}>
                  ราคา
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={10}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={11}>
                    <CustomInput
                      name='price1'
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        placeholder: "MVhub pack year",
                        type: "number",
                        defaultValue: '0',
                        min: '0',
                        required: true
                      }}
                      inputChange={inputChange}
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>
              {input.freq_c != 1 &&
                <>
                  <GridItem xs={12} sm={2}>
                    <FormLabel className={classes.labelHorizontal}>
                      วันหมดอายุ
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={5} lg={5}>
                    <FormControl
                      fullWidth
                      className={classesExtended.selectFormControl}
                    >
                      <InputLabel
                        htmlFor="pack_day"
                        className={classesExtended.selectLabel}
                      >
                        จำนวนวัน
                      </InputLabel>
                      <Select
                        MenuProps={{
                          className: classesExtended.selectMenu,
                        }}
                        classes={{
                          select: classesExtended.select,
                        }}
                        value={simpleSelect}
                        onChange={handleSimple}
                        inputProps={{
                          name: "pack_day",
                          id: "pack_day",
                          required: true
                        }}
                      >
                        <MenuItem
                          classes={{
                            root: classesExtended.selectMenuItem,
                            selected: classesExtended.selectMenuItemSelected,
                          }}
                          value="1"
                        >
                          1 วัน
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classesExtended.selectMenuItem,
                            selected: classesExtended.selectMenuItemSelected,
                          }}
                          value="3"
                        >
                          3 วัน
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classesExtended.selectMenuItem,
                            selected: classesExtended.selectMenuItemSelected,
                          }}
                          value="7"
                        >
                          7 วัน / 1 สัปดาห์
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classesExtended.selectMenuItem,
                            selected: classesExtended.selectMenuItemSelected,
                          }}
                          value="10"
                        >
                          10 วัน
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classesExtended.selectMenuItem,
                            selected: classesExtended.selectMenuItemSelected,
                          }}
                          value="15"
                        >
                          15 วัน / ครี่งเดือน
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classesExtended.selectMenuItem,
                            selected: classesExtended.selectMenuItemSelected,
                          }}
                          value="30"
                        >
                          30วัน / 1 เดือน
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classesExtended.selectMenuItem,
                            selected: classesExtended.selectMenuItemSelected,
                          }}
                          value="37"
                        >
                          37วัน
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classesExtended.selectMenuItem,
                            selected: classesExtended.selectMenuItemSelected,
                          }}
                          value="90"
                        >
                          90 วัน / 3 เดือน
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classesExtended.selectMenuItem,
                            selected: classesExtended.selectMenuItemSelected,
                          }}
                          value="110"
                        >
                          110วัน
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classesExtended.selectMenuItem,
                            selected: classesExtended.selectMenuItemSelected,
                          }}
                          value="180"
                        >
                          180 วัน / 6 เดือน
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classesExtended.selectMenuItem,
                            selected: classesExtended.selectMenuItemSelected,
                          }}
                          value="365"
                        >
                          365 วัน / 1 ปี
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classesExtended.selectMenuItem,
                            selected: classesExtended.selectMenuItemSelected,
                          }}
                          value="370"
                        >
                          370 วัน
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classesExtended.selectMenuItem,
                            selected: classesExtended.selectMenuItemSelected,
                          }}
                          value="379"
                        >
                          379 วัน
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classesExtended.selectMenuItem,
                            selected: classesExtended.selectMenuItemSelected,
                          }}
                          value="395"
                        >
                          395 วัน
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classesExtended.selectMenuItem,
                            selected: classesExtended.selectMenuItemSelected,
                          }}
                          value="410"
                        >
                          410วัน
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </GridItem>
                </>
              }
            </GridContainer>

            <GridContainer>
              <GridItem xs={12} sm={2}>
                <FormLabel className={classes.labelHorizontal}>
                  ประเภทของแพ็กเกจ
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={6} md={5} lg={5}>
                <FormControl
                  fullWidth
                  className={classesExtended.selectFormControl}
                >
                  <InputLabel
                    htmlFor="p_type"
                    className={classesExtended.selectLabel}
                  >
                    แพ็กเกจ
                  </InputLabel>
                  <Select
                    MenuProps={{
                      className: classesExtended.selectMenu,
                    }}
                    classes={{
                      select: classesExtended.select,
                    }}
                    value={simpleTypeSelect}
                    onChange={handleSimpleType}
                    inputProps={{
                      name: "p_type",
                      id: "p_type",
                      required: true
                    }}
                  >
                    <MenuItem
                      classes={{
                        root: classesExtended.selectMenuItem,
                        selected: classesExtended.selectMenuItemSelected,
                      }}
                      value="PG"
                    >
                      ปกติ
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classesExtended.selectMenuItem,
                        selected: classesExtended.selectMenuItemSelected,
                      }}
                      value="RD"
                    >
                      redeem
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classesExtended.selectMenuItem,
                        selected: classesExtended.selectMenuItemSelected,
                      }}
                      value="AA"
                    >
                      วันพ่อ
                    </MenuItem>
                  </Select>
                </FormControl>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={2}>
                <FormLabel className={classes.labelHorizontal}>
                  รูปแอป 1
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={10}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={11}>
                    {/* <input type='file' accept='image/*' /> */}
                    <CustomInput
                      name='fileY'
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        placeholder: "XX-XX-001",
                        accept: "=image/*",
                        type: 'file',
                      }}
                      inputChange={(event) => setInput((prev) => {
                        return {
                          ...prev,
                          // file: event.target.value
                          fileY: event.target.files[0]
                        }
                      })}
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={2}>
                <FormLabel className={classes.labelHorizontal}>
                  รูปเว็บไซต์ 1
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={10}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={11}>
                    <CustomInput
                      name='fileX'
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        placeholder: "XX-XX-001",
                        type: 'file'
                      }}
                      inputChange={(event) => setInput((prev) => {
                        return {
                          ...prev,
                          fileX: event.target.files[0]
                        }
                      })}
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={2}>
                <FormLabel className={classes.labelHorizontal}>
                  จำนวนเครื่องดูได้พร้อมกัน
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={10}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={11}>
                    <CustomInput
                      name='device_amount'
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        placeholder: "MVhub pack year",
                        type: "number",
                        defaultValue: '0',
                        min: '0'
                      }}
                      inputChange={inputChange}
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
            <Button type='submit' color='success' block>submit</Button>
          </form>
        </CardBody>
      </Card>
    </GridContainer>
  );
}
