import React, { useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// material-ui icons
import CircularProgress from '@material-ui/core/CircularProgress';
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";

// core components
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import SweetAlert from "react-bootstrap-sweetalert";
import Switch from "@material-ui/core/Switch";
import Badge from "components/Badge/Badge.js";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import stylesAlert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import axios from "axios";
import { Link } from "react-router-dom";

const useStyles = makeStyles(styles);
const useStylesAlert = makeStyles(stylesAlert);

export default function PackageListTable(props) {
  const { headers, datatable, setPackageStatus, loadingStatus, clickDelete, setEditStatus, setEditDataId } = props
  const [alert, setAlert] = useState(null);
  const classes = useStyles();
  const classesAlert = useStylesAlert()

  function deleteClick(id) {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="DELETE this package?"
        onConfirm={() => success(id)}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classesAlert.button + " " + classesAlert.success}
        cancelBtnCssClass={classesAlert.button + " " + classesAlert.danger}
        cancelBtnText="Cancel"
        confirmBtnText="Yes, Delete it!"
        showCancel
      >
      </SweetAlert>
    );
  }
  const success = (id) => {
    axios.post(`${process.env.REACT_APP_API}/data/deletepackage`, {
      id: id
    },
      {
        headers: {
          authorization: window.localStorage.getItem('userAccessToken')
        }
      }
    ).then(() => {
      clickDelete(id)
    })
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="DELETED!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classesAlert.button + " " + classesAlert.success}
      >
        Deleted.
      </SweetAlert>
    );
  };
  const hideAlert = () => {
    setAlert(null);
  };

  function statusClick(id, status) {
    if (window.confirm("Change Status ?")) {
      setPackageStatus(id, status)
    }
  }

  const renderDataTable = datatable.map(data => {
    return (
      [
        <p>
          {data.id}
        </p>,
        <Link to={`/admin/package/detail/${data.id}`}>
          <span key="key">
            {data.product_id}
            <br />
          </span>
        </Link>,
        <span>
          {data.pack_name}
          <br />
        </span>,
        <div className={classes.imgContainer} key="key">
          <img src={data.img_web} alt="..." className={classes.img} />
        </div>,
        <p>
          {data.pack_day}วัน
        </p>,
        <p>
          {data.price1}฿
        </p>,
        <p>
          {data.freq_c == 1 ? <Badge color='success'>Recurring</Badge> : <Badge color='warning'>One Time</Badge>}
        </p>,
        <>
          <Switch
            checked={Boolean(data.status)}
            onChange={(event) => statusClick(data.id, event.target.checked)
            }
            classes={{
              switchBase: classes.switchBase,
              checked: classes.switchChecked,
              thumb: classes.switchIcon,
              track: classes.switchBar,
            }}
          />
          <Button color='success' className={classes.actionButton} onClick={() => {
            setEditStatus(true)
            setEditDataId(data.id)
            window.location.replace('#edit')
          }
          }>
            <Edit className={classes.icon} />
          </Button>
          <Button color='danger' className={classes.actionButton} onClick={() => deleteClick(data.id)}>
            <Close className={classes.icon} />
          </Button>
        </>
      ]
    )
  })
  return (
    <Card>
      <CardBody>
        {loadingStatus == true &&
          <CircularProgress color="secondary" className='loading' />
        }
        {loadingStatus == false &&
          <Table
            tableHead={headers}
            tableData={renderDataTable}
            customHeadCellClasses={[
              classes.left,
              classes.description,
              classes.left,
              classes.left,
              classes.center,
              classes.right,
              classes.center,
              classes.right,
            ]}
            customHeadClassesForCells={[0, 1, 2, 3, 4, 5, 6, 7]}
            customCellClasses={[
              classes.customFont,
              classes.customFont,
              classes.customFont,
              classes.left,
              classes.center,
              classes.right,
              classes.center,
              classes.right,
            ]}
            customClassesForCells={[0, 1, 2, 3, 4, 5, 6, 7]}
          />
        }
        {alert}
      </CardBody>
    </Card>
  );
}
