import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// material-ui icons
import Assignment from "@material-ui/icons/Assignment";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import CircularProgress from '@material-ui/core/CircularProgress';


// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

const useStyles = makeStyles(styles);

export default function NoWatchTable(props) {
  const { headers, datatable, next, previous, clickPage, loadingStatus } = props
  const classes = useStyles();
  const renderDataTable = datatable.map(data => {
    return (
      [<div>
        {data.vod_id}
      </div>,
      <span key="key">
        <div className={classes.tdNameAnchor}>
          {data.ch_name}
        </div>
        <br />
      </span>,
      <div className={classes.imgContainer} key="key">
        <img src={data.imgY1} alt="..." className={classes.img} />
      </div>
      ]
    )
  })
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <Assignment />
            </CardIcon>
            <Button color={previous[0]} className={classes.marginRight} onClick={(e) => clickPage(e, previous[1])}>
              <KeyboardArrowLeft className={classes.icons} /> Previous
            </Button>
            <Button color={next[0]} className={classes.marginRight} onClick={(e) => clickPage(e, next[1])}>
              Next <KeyboardArrowRight className={classes.icons} />
            </Button>
          </CardHeader>
          <CardBody>
            {loadingStatus == true &&
              <CircularProgress color="secondary" className='loading' />
            }
            {loadingStatus == false &&
              <Table
                tableHead={headers}
                tableData={renderDataTable}
                customHeadCellClasses={[
                  classes.left,
                  classes.left,
                  classes.left,
                ]}
                customHeadClassesForCells={[0, 1, 2]}
                customCellClasses={[
                  classes.customFont,
                  classes.left,
                  classes.left,
                ]}
                customClassesForCells={[0, 1, 2]}
              />
            }
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
