require('dotenv').config()
import React, { useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import axios from 'axios'
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";

const useStyles = makeStyles(styles);

export default function LoginPage() {
  const [username, setUsername] = useState('')
  const [alertStatus, setAlertStatus] = useState(false)
  const [alertMsg, setAlertMsg] = useState(false)
  const [pass, setpass] = useState('')
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");

  function userChange(event) {
    setUsername(event.target.value)
  }
  function passChange(event) {
    setpass(event.target.value)
  }
  function closeAlert() {
    setAlertStatus(false)
  }
  const loginClick = (e) => {
    e.preventDefault()
    axios.post(`${process.env.REACT_APP_API}/auth`, { 'username': username, 'password': pass })
      .then((result) => {
        if (result.data.res_code == '200') {
          window.localStorage.setItem('role', result.data.role)
          window.localStorage.setItem('userAccessToken', result.data.token)
          window.location.href = `http://${window.location.host}/admin/searchuser/`
        } else {
          setAlertStatus(true)
          setAlertMsg(result.data.message)
        }
      })
  }
  React.useEffect(() => {
    let id = setTimeout(function () {
      setCardAnimation("");
    }, 700);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  });
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
          <form onSubmit={loginClick}>
            <Card login className={classes[cardAnimaton]}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="rose"
              >
                <h3 className={classes.cardTitle}>Log in</h3>
              </CardHeader>
              <CardBody>
                <CustomInput
                  labelText="Username"
                  name="username"
                  inputChange={userChange}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                      >
                        <Face className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    ),
                  }}
                />
                <CustomInput
                  labelText="Password"
                  name="password"
                  inputName='password'
                  inputChange={passChange}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon className={classes.inputAdornmentIcon}>
                          lock_outline
                        </Icon>
                      </InputAdornment>
                    ),
                    type: "password",
                    autoComplete: "off",
                  }}
                />
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <Button type="submit" color="rose" simple size="lg" block>
                  Let{"'"}s Go
                </Button>
              </CardFooter>
            </Card>
            {alertStatus === true &&
              <SnackbarContent
                message={
                  alertMsg
                }
                close
                color="danger"
                closeAlert={closeAlert}
              />
            }
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}
