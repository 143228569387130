import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// @material-ui/icons
import LibraryBooks from "@material-ui/icons/LibraryBooks";
// core components
import Datetime from "react-datetime";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import stylesExtended from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import '../../assets/css/style.css'

const useStyles = makeStyles(styles);
const useStylesExtended = makeStyles(stylesExtended);

export default function DateForm(props) {
    const { dateChange, input, handleSimple } = props
    const classes = useStyles();
    const classesExtended = useStylesExtended();
    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <FormControl
                    fullWidth
                    className={classesExtended.selectFormControl}
                >
                    <InputLabel
                        htmlFor="datecategory"
                        className={classesExtended.selectLabel}
                    >
                        ค้นหาโดย
                    </InputLabel>
                    <Select
                        MenuProps={{
                            className: classesExtended.selectMenu,
                        }}
                        classes={{
                            select: classesExtended.select,
                        }}
                        value={input}
                        onChange={handleSimple}
                        inputProps={{
                            name: "datecategory",
                            id: "datecategory",
                            required: true
                        }}
                    >
                        <MenuItem
                            classes={{
                                root: classesExtended.selectMenuItem,
                                selected: classesExtended.selectMenuItemSelected,
                            }}
                            value="userapp.create_date"
                        >
                            วันที่สมัครสมาชิก
                        </MenuItem>
                        <MenuItem
                            classes={{
                                root: classesExtended.selectMenuItem,
                                selected: classesExtended.selectMenuItemSelected,
                            }}
                            value="user_package.expire_pack"
                        >
                            วันหมดอายุแพ็คเกจ
                        </MenuItem>
                    </Select>
                </FormControl>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
                <Card>
                    <CardHeader color="success" icon>
                        <CardIcon color="success">
                            <LibraryBooks />
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>ค้นหาตั้งแต่วันที่</h4>
                    </CardHeader>
                    <CardBody>
                        <FormControl fullWidth>
                            <Datetime
                                timeFormat={false}
                                inputProps={{ placeholder: "ตั้งแต่" }}
                                name='startdate'
                                onChange={(event) => dateChange(event._d, 'startdate')}
                            />
                        </FormControl>
                    </CardBody>
                </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
                <Card>
                    <CardHeader color="success" icon>
                        <CardIcon color="success">
                            <LibraryBooks />
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>ค้นหาจนถึงวันที่</h4>
                    </CardHeader>
                    <CardBody>
                        <FormControl fullWidth>
                            <Datetime
                                timeFormat={false}
                                inputProps={{ placeholder: "จนถึง" }}
                                onChange={(event) => dateChange(event._d, 'enddate')}
                            />
                        </FormControl>
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
    )
}
