import React, { useEffect, useState } from "react";
// react component plugin for creating a beautiful datetime dropdown picker
import Datetime from "react-datetime";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";

// @material-ui/icons
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import CircularProgress from '@material-ui/core/CircularProgress';
// core components
import Accordion from "components/Accordion/Accordion.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import axios from "axios";
import HistoryPaymentTable from "views/Tables/HistoryPaymentTable";
import { InputLabel } from "@material-ui/core";
import CustomInput from "components/CustomInput/CustomInput";

const useStyles = makeStyles(styles);

export default function HistoryPaymentForm() {
  const [input, setInput] = useState({ user_id: '', Ref_No: '' })
  const [datas, setDatas] = useState({})
  const [loadingStatus, setLoadingStatus] = useState(true)
  const [status, setStatus] = useState(false)
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState()

  function inputChange(_d, name) {
    setInput((prev) => {
      return {
        ...prev,
        [name]: _d
      }
    })
  }

  function inputFormChange(data) {
    const { name, value } = data.target
    setInput((prev) => {
      return {
        ...prev,
        [name]: value
      }
    })
  }

  function submit() {
    if (Object.keys(input).length = 2) {
      setLoadingStatus(true)
      axios.post(`${process.env.REACT_APP_API}/form/historypayment?page=1`, input, {
        headers: {
          authorization: window.localStorage.getItem('userAccessToken')
        }
      }).then(result => {
        setDatas(result.data.result)
        setTotalPages(result.data.totalPages)
        setPage(page)
      }).finally(() => {
        setLoadingStatus(false)
        setStatus(true)
      })
    }
  }
  function clickPage(page) {
    setLoadingStatus(true)
    axios.post(`${process.env.REACT_APP_API}/form/historypayment?page=${page}`, input, {
      headers: {
        authorization: window.localStorage.getItem('userAccessToken')
      }
    }).then(result => {
      setDatas(result.data.result)
      setTotalPages(result.data.totalPages)
      setPage(page)
    }).finally(() => {
      setLoadingStatus(false)
      setStatus(true)
    })
  }

  function queryOne() {
    setInput({
      ...input,
      startdate: new Date().setDate(new Date().getDate()), enddate: new Date().setDate(new Date().getDate())
    })
    setLoadingStatus(true)
    axios.post(`${process.env.REACT_APP_API}/form/historypayment?page=1`,
      {
        ...input,
        startdate: new Date().setDate(new Date().getDate()), enddate: new Date().setDate(new Date().getDate())
      }, {
      headers: {
        authorization: window.localStorage.getItem('userAccessToken')
      }
    }).then(result => {
      setDatas(result.data.result)
      setTotalPages(result.data.totalPages)
      setPage(1)
    }).finally(() => {
      setLoadingStatus(false)
      setStatus(true)
    })
  }

  function querySeven() {
    setInput({
      ...input,
      startdate: new Date().setDate(new Date().getDate() - 6), enddate: new Date().setDate(new Date().getDate())
    })
    setLoadingStatus(true)
    axios.post(`${process.env.REACT_APP_API}/form/historypayment?page=1`,
      {
        ...input,
        startdate: new Date().setDate(new Date().getDate() - 6), enddate: new Date().setDate(new Date().getDate())
      }, {
      headers: {
        authorization: window.localStorage.getItem('userAccessToken')
      }
    }).then(result => {
      setDatas(result.data.result)
      setTotalPages(result.data.totalPages)
      setPage(1)
    }).finally(() => {
      setLoadingStatus(false)
      setStatus(true)
    })
  }

  function queryThirty() {
    setInput({
      ...input,
      startdate: new Date().setDate(new Date().getDate() - 29), enddate: new Date().setDate(new Date().getDate())
    })
    setLoadingStatus(true)
    axios.post(`${process.env.REACT_APP_API}/form/historypayment?page=1`,
      {
        ...input,
        startdate: new Date().setDate(new Date().getDate() - 29), enddate: new Date().setDate(new Date().getDate())
      }, {
      headers: {
        authorization: window.localStorage.getItem('userAccessToken')
      }
    }).then(result => {
      setDatas(result.data.result)
      setTotalPages(result.data.totalPages)
      setPage(1)
    }).finally(() => {
      setLoadingStatus(false)
      setStatus(true)
    })
  }

  useEffect(() => {
    if (!window.localStorage.getItem('userAccessToken')) {
      window.location.href = `http://${window.location.host}/auth`
    }
    setLoadingStatus(true)
    setInput({
      ...input,
      startdate: new Date().setDate(new Date().getDate()), enddate: new Date().setDate(new Date().getDate())
    })
    axios.post(`${process.env.REACT_APP_API}/form/historypayment?page=1`,
      {
        ...input,
        startdate: new Date().setDate(new Date().getDate()), enddate: new Date().setDate(new Date().getDate())
      }, {
      headers: {
        authorization: window.localStorage.getItem('userAccessToken')
      }
    }).then(result => {
      setDatas(result.data.result)
      setTotalPages(result.data.totalPages)
      setPage(1)
    }).finally(() => {
      setLoadingStatus(false)
      setStatus(true)
    })
  }, [])
  const classes = useStyles();
  return (
    <div>
      <GridContainer>
        <GridContainer
          justify="center"
        >
          <div className={classes.buttonGroup}>
            <Button color="info" className={classes.firstButton} onClick={() => queryThirty()}>
              30 Days
            </Button>
            <Button color="rose" className={classes.middleButton} onClick={() => querySeven()}>
              7 Days
            </Button>
            <Button color="primary" className={classes.lastButton} onClick={() => queryOne()}>
              1 Day
            </Button>
          </div>
        </GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <LibraryBooks />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>ค้นหาโดยวันที่สมัคร ตั้งแต่</h4>
            </CardHeader>
            <CardBody>
              <FormControl fullWidth>
                <Datetime
                  timeFormat={false}
                  inputProps={{ placeholder: "ตั้งแต่" }}
                  name='startdate'
                  onChange={(event) => inputChange(event._d, 'startdate')}
                />
              </FormControl>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <LibraryBooks />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>ค้นหาโดยวันที่สมัคร จนถึง</h4>
            </CardHeader>
            <CardBody>
              <FormControl fullWidth>
                <Datetime
                  timeFormat={false}
                  inputProps={{ placeholder: "จนถึง" }}
                  onChange={(event) => inputChange(event._d, 'enddate')}
                />
              </FormControl>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Card>
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <Accordion
                collapses={[
                  {
                    title: "Filter (เครื่องมือค้นหาเพิ่มเติม)",
                    content: (
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                          <FormControl
                            fullWidth
                            className={classes.selectFormControl}
                          >
                            <InputLabel
                              htmlFor="user_id"
                              className={classes.selectLabel}
                            >
                              Ref_No
                            </InputLabel>
                          </FormControl>
                          <CustomInput
                            // labelText="ช่องค้นหา"
                            id="Ref_No"
                            name='Ref_No'
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              type: "text",
                              defaultValue: input.Ref_No
                            }}
                            inputChange={inputFormChange}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <FormControl
                            fullWidth
                            className={classes.selectFormControl}
                          >
                            <InputLabel
                              htmlFor="user_id"
                              className={classes.selectLabel}
                            >
                              user-id
                            </InputLabel>
                          </FormControl>
                          <CustomInput
                            // labelText="ช่องค้นหา"
                            id="user_id"
                            name='user_id'
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              type: "text",
                              defaultValue: input.user_id
                            }}
                            inputChange={inputFormChange}
                          />
                        </GridItem>
                      </GridContainer>
                    ),
                  },
                ]}
              />
            </GridItem>
          </Card>
        </GridItem>
      </GridContainer >
      <Button color="rose" className={classes.marginRight} block onClick={submit}>
        Search
      </Button>
      {
        loadingStatus == true && status == false &&
        <CircularProgress color="secondary" className='loading' />
      }
      <GridItem xs={12} sm={12} md={12}>
        {status == true &&
          <HistoryPaymentTable
            loadingStatus={loadingStatus}
            headers={['Ref No.', 'User ID', 'Package', 'Status', 'Paid', 'Date']}
            datatable={datas}
            page={page}
            totalPages={totalPages}
            clickPage={clickPage}
          />
        }
      </GridItem>
    </div >
  );
}
